import { ComponentType } from '@angular/cdk/portal';
import { Injector, inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  Observable,
  UnaryFunction,
  filter,
  from,
  map,
  pipe,
  switchMap,
} from 'rxjs';

/**
 * dialogResultOperator is a helper function that returns an RxJS operator.
 * This operator takes an Observable of MatDialogRef, subscribes to the
 * afterClosed() observable of the dialog, and filters the emitted values
 * based on the provided condition function, defaulting to filtering out
 * falsy values.
 */
export const NoDialogResultFilter = (): boolean => true;
export const dialogResultOperator = <T, TReturn>(
  condition: (value: TReturn) => boolean = value => !!value
): UnaryFunction<Observable<MatDialogRef<T, TReturn>>, Observable<TReturn>> =>
  pipe(
    switchMap(matDialogRef => matDialogRef.afterClosed()),
    filter(condition)
  );

export abstract class BaseDeferDialogService {
  private _dialog = inject(MatDialog);
  private _injector = inject(Injector);

  closeAll(): void {
    this._dialog.closeAll();
  }

  getDialogById(id: string): MatDialogRef<any> {
    return this._dialog.getDialogById(id);
  }

  protected lazyOpenDialog<T, TData, TReturn>(
    getComponent: () => Promise<T>,
    data?: TData
  ): Observable<MatDialogRef<T, TReturn | undefined>> {
    return from(getComponent()).pipe(
      map(component => {
        const dialogConfig = data
          ? (component as any).options(data)
          : (component as any).options();
        dialogConfig.injector = this._injector;

        return this._dialog.open(component as ComponentType<T>, dialogConfig);
      })
    );
  }
}
