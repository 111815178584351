<div
  class="status-indicator"
  [style.width.px]="width"
  [style.height.px]="width"
>
  @switch (statusClass$ | async) {
    <!-- STARTED -->
    @case (showSpinner) {
      <mat-progress-spinner
        [diameter]="diameter"
        [strokeWidth]="strokeWidth"
        mode="indeterminate"
        color="primary"
        class="started"
      />
    }
    <!-- Other -->
    @default {
      <fa-icon
        [icon]="statusIcon$ | async"
        [size]="faCssSize"
        [matTooltip]="toolTip"
        [class]="statusClass$ | async"
      />
    }
  }
</div>
