import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IRosettaConfig, ROSETTA_CONFIG } from '@configs';
import {
  IDomainModelDetails,
  IDomainModelResponse,
  WorkspaceGroup,
} from '@models';
import { ModelInstanceId } from '@models/domain-models';
import { ReleaseInfoDto } from '@models/dto';
import { PagedResultsDto } from '@models/dto/paged-results.dto';
import { QueryParamFilters } from '@models/query-param-filters';
import { Store } from '@ngrx/store';
import { AuthSelectors } from '@store/selectors';
import {
  domainModelMapper,
  mapToWorkspaceGroups,
} from '@store/workspace/effects/workspace.effects.helper';
import { Observable, first, map, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DomainModelApiService {
  constructor(
    private _http: HttpClient,
    private _store: Store,
    @Inject(ROSETTA_CONFIG) private _config: IRosettaConfig
  ) {}

  getAll(): Observable<IDomainModelDetails[]> {
    return this._store.select(AuthSelectors.selectUserDocuments).pipe(
      first(),
      switchMap(userDocuments =>
        this._http
          .get<IDomainModelResponse[]>(`${this._config.resourcePaths.models}`)
          .pipe(
            map(domainModels => domainModelMapper(domainModels, userDocuments))
          )
      )
    );
  }

  getOwned(): Observable<string[]> {
    return this._http.get<string[]>(
      `${this._config.resourcePaths.models}/owned`
    );
  }

  getProjects(): Observable<WorkspaceGroup[]> {
    return this._http
      .get<IDomainModelResponse[]>(this._config.resourcePaths.models)
      .pipe(map(domainModels => mapToWorkspaceGroups(domainModels)));
  }

  getAppDocument(documentName: string): Observable<string> {
    return this._http
      .get<{
        payload: string;
      }>(`${this._config.resourcePaths.userServer}/document/${documentName}`)
      .pipe(map(data => data.payload));
  }

  getModelDocument(
    modelId: ModelInstanceId,
    documentName: string
  ): Observable<string> {
    return this._http
      .get<{
        payload: string;
      }>(
        `${this._config.resourcePaths.models}/${modelId}/document/${documentName}`
      )
      .pipe(map(data => data.payload));
  }

  getModelReleaseNotes(
    modelId: ModelInstanceId,
    filter: QueryParamFilters
  ): Observable<PagedResultsDto<ReleaseInfoDto>> {
    return this._http.get<PagedResultsDto<ReleaseInfoDto>>(
      `${this._config.resourcePaths.models}/${modelId}/versions/release-notes?${filter.queryString()}`
    );
  }
}
