import { HttpClient, HttpContext } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DownloadType, IRosettaConfig, ROSETTA_CONFIG } from '@configs';
import { HTTP_CONTEXT_TOKEN } from '@core/interceptors/dynamic-url-path-interceptor';
import {
  CreateWorkspaceRequest,
  IDomainModelResponse,
  NamespaceFormRequest,
  SymbolIdentifier,
  UpdateWorkspaceResponse,
  WorkspaceId,
  WorkspaceInfo,
  WorkspaceItem,
  WorkspaceItemState,
  WorkspaceMenuItem,
  WorkspaceSummaryResponse,
} from '@models';
import { ModelInstanceId } from '@models/domain-models';
import {
  ContributionDto,
  ContributionRequestDto,
  ReleaseInfoDto,
  UpdateContributionDto,
  WorkspaceDto,
  WorkspaceInfoDto,
} from '@models/dto';
import { Store } from '@ngrx/store';
import { NodeNameType } from '@shared/modules/code-view/models/code-view.model';
import { createWorkspaceMenuItems } from '@store/workspace/effects/workspace.effects.helper';
import { domainModelToWorkspaceMapper } from '@store/workspace/reducers/workspace.reducers.helper';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import {
  WorkspaceUrlPrefixOperator,
  deleteHttpPostFallback,
  firstNotNullAndComplete,
} from '@utils/operators';
import { saveAs } from 'file-saver';
import {
  Observable,
  catchError,
  first,
  map,
  of,
  shareReplay,
  switchMap,
  tap,
  zip,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceApiService {
  constructor(
    private _http: HttpClient,
    private _store: Store,
    private _sanitizer: DomSanitizer,
    @Inject(ROSETTA_CONFIG) private _config: IRosettaConfig
  ) {}

  #assetCache = new Map<string, Observable<SafeUrl | null>>();
  #modelsRequestCache?: IDomainModelResponse[];

  getAsset(modelId: ModelInstanceId): Observable<SafeUrl | null> {
    if (this.#assetCache.has(modelId)) {
      const value = this.#assetCache.get(modelId);
      if (value) {
        return value;
      }
    }

    const data$ = this._http
      .get(
        `${this._config.resourcePaths.workspaceCore}/load-asset/${modelId}`,
        {
          responseType: 'blob',
        }
      )
      .pipe(
        map(val =>
          this._sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val))
        ),
        catchError(e => {
          if (e.status === 404) {
            return of(null);
          }
          throw e;
        }),
        shareReplay(1)
      );

    this.#assetCache.set(modelId, data$);

    return data$;
  }

  getModels(refreshCache = false): Observable<IDomainModelResponse[]> {
    if (!refreshCache && this.#modelsRequestCache) {
      return of(this.#modelsRequestCache);
    }
    this.#modelsRequestCache = undefined;

    return this._http
      .get<IDomainModelResponse[]>(this._config.resourcePaths.models)
      .pipe(
        tap(models => (this.#modelsRequestCache = models)),
        shareReplay(1)
      );
  }

  getWorkspaceMenuItem(): Observable<WorkspaceMenuItem[]> {
    return zip([
      this._http.get<WorkspaceInfoDto[]>(
        `${this._config.resourcePaths.workspaceCore}/workspaceInfos`
      ),
      this.getModels(),
    ]).pipe(
      map(([infos, models]) => {
        return [
          ...infos.map(info => {
            return createWorkspaceMenuItems(
              info,
              models.find(m => m.id === info.modelId)
            );
          }),
          ...domainModelToWorkspaceMapper(models),
        ];
      })
    );
  }

  getWorkspaceInfo(workspaceName: string): Observable<WorkspaceInfo> {
    return this._http.get<WorkspaceInfo>(
      `${this._config.resourcePaths.workspace}/workspaceInfo/${workspaceName}`,
      {
        context: this._createWorkspaceNameContext(workspaceName),
      }
    );
  }

  getWorkspace(
    workspaceName: string,
    setAsCurrent: boolean
  ): Observable<WorkspaceDto> {
    return this._http
      .get<WorkspaceDto>(
        `${this._config.resourcePaths.workspace}/${workspaceName}`,
        {
          context: this._createWorkspaceNameContext(workspaceName),
        }
      )
      .pipe(
        switchMap(workspace =>
          setAsCurrent
            ? this.setLastUsed(workspaceName).pipe(map(() => workspace))
            : of(workspace)
        )
      );
  }

  setLastUsed(workspaceName?: string): Observable<void> {
    return this._http.post<void>(
      `${this._config.resourcePaths.workspaceCore}/set-last-used`,
      workspaceName || null
    );
  }

  getLastUsed(): Observable<WorkspaceInfo | null> {
    return this._http
      .get(`${this._config.resourcePaths.workspaceCore}/last-used-workspace`, {
        responseType: 'text',
      })
      .pipe(
        switchMap(workspaceName =>
          workspaceName ? this.getWorkspaceInfo(workspaceName) : of(null)
        )
      );
  }

  createWorkspace(request: CreateWorkspaceRequest): Observable<WorkspaceDto> {
    return this._http.post<WorkspaceDto>(
      `${this._config.resourcePaths.workspaceCore}/create-workspace`,
      request
    );
  }

  // TODO: Add workspace cleanup logic for localStorage, SessionStorage and IndexedDB
  deleteWorkspace(workspaceId: WorkspaceId): Observable<WorkspaceId> {
    return this._http
      .delete<WorkspaceId>(
        `${this._config.resourcePaths.workspaceCore}/${workspaceId.name}`
      )
      .pipe(deleteHttpPostFallback<WorkspaceId>(this._http));
  }

  createNewNamespaceFile(
    payload: NamespaceFormRequest
  ): Observable<WorkspaceItem> {
    return this._store.pipe(
      WorkspaceSelectors.getWorkspaceId,
      first(),
      switchMap(workspaceId =>
        this._http.post<WorkspaceItem>(
          `${this._config.resourcePaths.workspaceCore}/${workspaceId.name}/create-namespace`,
          payload
        )
      )
    );
  }

  saveWorkspaceItem(
    workspaceItemState: WorkspaceItemState
  ): Observable<WorkspaceItem> {
    const workspaceItem: WorkspaceItem = {
      info: workspaceItemState.info,
      contents: workspaceItemState.contents,
      originalContents: workspaceItemState.originalContents,
    };
    return this._store.select(WorkspaceSelectors.selectWorkspaceId).pipe(
      firstNotNullAndComplete(),
      switchMap(({ name }) => {
        return this._http.post<WorkspaceItem>(
          `${this._config.resourcePaths.workspaceCore}/${name}`,
          workspaceItem
        );
      })
    );
  }

  createContribution(request: ContributionRequestDto): Observable<void> {
    return this._http.post<void>(
      `${this._config.resourcePaths.contributions}/create`,
      request
    );
  }

  updateContribution(request: UpdateContributionDto): Observable<void> {
    return this._http.put<void>(
      `${this._config.resourcePaths.contributions}/update`,
      request
    );
  }

  getContribution(): Observable<ContributionDto> {
    return this._store.pipe(
      WorkspaceSelectors.getWorkspaceId,
      firstNotNullAndComplete(),
      switchMap(workspaceId =>
        this._http.get<ContributionDto>(
          `${this._config.resourcePaths.contributions}/${workspaceId.name}`
        )
      )
    );
  }

  workspaceExists(workspaceName: string): Observable<boolean> {
    return this._http.get<boolean>(
      `${this._config.resourcePaths.workspace}/${workspaceName}/exists`,
      {
        context: new HttpContext().set(HTTP_CONTEXT_TOKEN, workspaceName),
      }
    );
  }

  downloadAndZipWorkspace(downloadType: DownloadType): Observable<Blob> {
    return this._store.select(WorkspaceSelectors.selectWorkspaceId).pipe(
      firstNotNullAndComplete(),
      switchMap(({ name }) =>
        this._http
          .get(
            `${this._config.resourcePaths.workspace}/${name}/download${downloadType}`,
            {
              responseType: 'blob',
            }
          )
          .pipe(
            tap(data =>
              saveAs(data, `${name}-${downloadType.toLowerCase()}.zip`)
            )
          )
      )
    );
  }

  getModelUpgradeReleaseNotes(
    modelId: ModelInstanceId,
    currentVersion: string,
    upgradeVersion: string
  ): Observable<ReleaseInfoDto[]> {
    return this._http.get<ReleaseInfoDto[]>(
      `${this._config.resourcePaths.models}/${modelId}/versions/release-notes/${currentVersion}/${upgradeVersion}`
    );
  }

  updateWorkspace(
    modelVersion: string | undefined
  ): Observable<UpdateWorkspaceResponse> {
    return this._store.select(WorkspaceSelectors.selectWorkspaceId).pipe(
      firstNotNullAndComplete(),
      switchMap(({ name }) =>
        this._http.post<UpdateWorkspaceResponse>(
          `${this._config.resourcePaths.workspaceCore}/update-workspace`,
          {
            name,
            modelVersion,
          }
        )
      )
    );
  }

  getOpenContributions(): Observable<WorkspaceSummaryResponse[]> {
    return this._http.get<WorkspaceSummaryResponse[]>(
      this._config.resourcePaths.contributions
    );
  }

  getSymbolFromType(nodeType: NodeNameType): Observable<SymbolIdentifier> {
    return this._store.pipe(
      WorkspaceUrlPrefixOperator(this._config.resourcePaths.symbol),
      switchMap(baseUrl =>
        this._http.get<SymbolIdentifier>(
          `${baseUrl}/${nodeType.parentType ? `${nodeType.parentType}/${nodeType.name}` : nodeType.name}`
        )
      )
    );
  }

  private _createWorkspaceNameContext(workspaceName: string): HttpContext {
    return new HttpContext().set(HTTP_CONTEXT_TOKEN, workspaceName);
  }
}
