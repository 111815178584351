import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontsModule } from '@app/fonts/fonts.module';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { TaskStatus } from '@models';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  concat,
  distinctUntilChanged,
  map,
  of,
  startWith,
  takeWhile,
  tap,
} from 'rxjs';

@Component({
  standalone: true,
  imports: [AsyncPipe, FontsModule, MatProgressSpinnerModule, MatTooltipModule],
  selector: 'app-status-indicator',
  templateUrl: './status-indicator.component.html',
  styleUrls: ['./status-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusIndicatorComponent implements OnInit {
  readonly showSpinner = TaskStatus.Started.toLowerCase();
  statusClass$!: Observable<string>;

  @Input() upstreamTaskStatus$ = of(TaskStatus.Finished);
  @Input() toolTip = '';
  @Input() diameter = 42;
  @Input() strokeWidth = 3;
  @Input() faCssSize: SizeProp = 'xs';
  @Input() width = this.diameter + this.strokeWidth;

  private _taskStatus$: Observable<TaskStatus> = EMPTY;

  @Input({ required: true })
  set taskStatus$(taskStatus: Observable<TaskStatus>) {
    this._taskStatus$ = taskStatus;
  }
  get taskStatus$(): Observable<TaskStatus> {
    if (!this._taskStatus$) {
      return of(TaskStatus.Pending);
    }
    return this._taskStatus$.pipe(
      startWith(TaskStatus.Pending),
      tap(status =>
        this.statusIcon$.next(
          status === TaskStatus.Partial ? 'circle-half-stroke' : 'circle'
        )
      )
    );
  }

  statusIcon$ = new BehaviorSubject<IconProp>('circle');

  ngOnInit(): void {
    const upstream = this.upstreamTaskStatus$.pipe(
      startWith(TaskStatus.Pending),
      map(status =>
        status === TaskStatus.Finished
          ? TaskStatus.Finished
          : TaskStatus.Pending
      ),
      takeWhile(status => status !== TaskStatus.Finished)
    );

    this.statusClass$ = concat(upstream, this.taskStatus$).pipe(
      distinctUntilChanged(),
      map(s => s.toLowerCase())
    );
  }
}
