import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserDetailsDto } from '@features/auth/login';
import {
  ProjectLogoComponent,
  Sizes,
} from '@shared/components/project-logo/project-logo.component';
import { ModelPipe } from '@shared/pipes';

export interface ProjectCellParams<T = unknown> {
  getModelIds(data?: T): string[];
  maxCount?: number;
  size?: Sizes;
}

@Component({
  standalone: true,
  imports: [AsyncPipe, ProjectLogoComponent, ModelPipe],
  selector: 'app-project-cell',
  templateUrl: './project-cell.component.html',
  styleUrls: ['./project-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectCellComponent implements ICellRendererAngularComp {
  @Input() modelIds: string[] = [];
  @Input() size: Sizes = 'sm';
  @Input() maxCount = 6;

  agInit({
    getModelIds,
    data,
    size = 'sm',
  }: ICellRendererParams<UserDetailsDto> & ProjectCellParams): void {
    this.size = size;
    this.modelIds = (getModelIds && getModelIds(data)) || [];
  }

  refresh(): boolean {
    return false;
  }
}
