import {
  Attribute,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FontsModule } from '@app/fonts/fonts.module';
import { InOutAnimation } from '@shared/animations';
import {
  InputValueDirective,
  StopPropagationDirective,
} from '@shared/directives';

const EMPTY_VALUE = '';

@Component({
  standalone: true,
  imports: [
    FontsModule,
    MatInputModule,
    InputValueDirective,
    MatButtonModule,
    StopPropagationDirective,
  ],
  selector: 'app-filter-field',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [InOutAnimation],
  templateUrl: './filter-field.component.html',
  styles: [
    `
      :host {
        display: block;
      }

      button {
        padding: 1.25rem;
      }

      input {
        border: 0;
        outline: none;
        background-color: transparent;
      }
    `,
  ],
  host: {
    class: 'filter-field',
  },
})
export class FilterFieldComponent {
  @ViewChild('searchInput') searchInput!: ElementRef<HTMLInputElement>;

  @Input() opened = false;
  @Output() inputChanged = new EventEmitter<string>();

  @HostBinding('class.filter-field-opened')
  get openedClass(): boolean {
    return this.opened;
  }

  inputValue = EMPTY_VALUE;

  constructor(@Attribute('placeholder') public placeholder: string) {}

  updateInputValue(value: string): void {
    this.inputValue = value;
    this.inputChanged.emit(value);
  }

  focusInput(): void {
    this.searchInput?.nativeElement.focus();
  }

  clear(): void {
    if (this.searchInput) {
      this.searchInput.nativeElement.value = EMPTY_VALUE;
    }
    this.updateInputValue(EMPTY_VALUE);
    this.focusInput();
  }

  animationDone(): void {
    if (this.opened) {
      this.focusInput();
    } else {
      this.clear();
    }
  }
}
