<tour-step-template>
  <ng-template let-step="step">
    <div
      [style.width]="step.stepDimensions?.width"
      [style.min-width]="step.stepDimensions?.minWidth"
      [style.max-width]="step.stepDimensions?.maxWidth"
      [class]="step.popoverClass"
      class="rosetta-tour-wrapper"
      appStopPropagation
    >
      <button
        mat-icon-button
        class="rosetta-close-button small-button-icon"
        (click)="tourService.end()"
      >
        <fa-icon icon="xmark" />
      </button>

      <h4 [innerHTML]="step?.title"></h4>
      <p [innerHTML]="step?.content" class="color-50"></p>

      <div
        class="flex-row flex-row-center small-button-wrapper"
        [hidden]="!step.showProgress || tourService.steps.length === 1"
      >
        <button
          mat-icon-button
          class="prev"
          [disabled]="!tourService.hasPrev(step)"
          (click)="tourService.prev()"
        >
          {{ step?.prevBtnTitle }}
        </button>

        <button
          mat-icon-button
          [disabled]="!tourService.hasPrev(step)"
          (click)="tourService.prev()"
        >
          <fa-icon icon="arrow-left" />
        </button>

        @if (step.showProgress) {
          <div class="progress">
            {{ tourService.steps.indexOf(step) + 1 }} /
            {{ tourService.steps.length }}
          </div>
        }

        @if (tourService.hasNext(step) && !step.nextOnAnchorClick) {
          <button mat-icon-button (click)="tourService.next()">
            <fa-icon icon="arrow-right" />
          </button>
        }

        <div class="spacer"></div>

        @if (!tourService.hasNext(step)) {
          <button
            mat-stroked-button
            color="primary"
            (click)="tourService.end()"
          >
            Got it
          </button>
        }
      </div>
    </div>
  </ng-template>
</tour-step-template>
