/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Injectable } from '@angular/core';
import { FormConfig } from '@configs';
import { DomainModelApiService } from '@core/services';
import { BaseDeferDialogService } from '@core/services/base-defer-dialog.service';
import { ReleaseNotesApiService } from '@core/services/release-notes/release-notes-api.service';
import { IProjectLicenseDialogData } from '@models';
import { ModelInstanceId } from '@models/domain-models';
import { Observable, tap } from 'rxjs';
import { ConfirmationData } from './confirm-dialog/confirm-dialog.component';
import { ModelReleaseNotesService } from './release-notes-viewer-dialog/services/model-release-notes.service';
import { RosettaReleaseNotesService } from './release-notes-viewer-dialog/services/rosetta-release-notes.service';
import { ReleaseNotesDialogData } from './release-notes-viewer-dialog/release-notes-viewer-dialog.component';
import { MatDialogRef } from '@angular/material/dialog';
import { ProjectLicensingTermsDialogComponent } from './project-licensing-terms-dialog/project-licensing-terms-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class CoreDialogService extends BaseDeferDialogService {
  constructor(
    private _domainModelApi: DomainModelApiService,
    private _releaseNotesApi: ReleaseNotesApiService
  ) {
    super();
  }

  openIssueTracker() {
    return this.lazyOpenDialog(() =>
      import('./issue-tracker/issue-tracker.component').then(
        m => m.IssueTrackerComponent
      )
    ).subscribe();
  }

  openContribute() {
    return this.lazyOpenDialog(() =>
      import('./contribute-dialog/contribute-dialog.component').then(
        m => m.ContributeDialogComponent
      )
    );
  }

  openDownloadWorkspace() {
    return this.lazyOpenDialog(() =>
      import(
        './download-workspace-dialog/download-workspace-dialog.component'
      ).then(m => m.DownloadWorkspaceDialogComponent)
    );
  }

  openUpgradeWorkspace() {
    return this.lazyOpenDialog(() =>
      import('./upgrade-dialog/upgrade-dialog.component').then(
        m => m.UpgradeDialogComponent
      )
    );
  }

  openUpgradePlan(data: FormConfig & { offer: boolean }) {
    return this.lazyOpenDialog(
      () =>
        import('./upgrade-plan-dialog/upgrade-plan-dialog.component').then(
          m => m.UpgradePlanDialogComponent
        ),
      data
    );
  }

  openCreateNamespace() {
    return this.lazyOpenDialog(() =>
      import(
        './create-namespace-dialog/create-namespace-dialog.component'
      ).then(m => m.CreateNamespaceDialogComponent)
    );
  }

  openProjectLicense(
    data: IProjectLicenseDialogData
  ): Observable<
    MatDialogRef<typeof ProjectLicensingTermsDialogComponent, boolean>
  > {
    return this.lazyOpenDialog(
      () =>
        import(
          './project-licensing-terms-dialog/project-licensing-terms-dialog.component'
        ).then(m => m.ProjectLicensingTermsDialogComponent),
      data
    );
  }

  openConfirmation(data: ConfirmationData, disableClose = false) {
    return this.lazyOpenDialog(
      () =>
        import('./confirm-dialog/confirm-dialog.component').then(
          m => m.ConfirmDialogComponent
        ),
      data
    ).pipe(
      tap(dialogRef => {
        dialogRef.disableClose = disableClose;
      })
    );
  }

  openModelReleaseNotes(modelId: ModelInstanceId, modelName: string) {
    return this._openReleaseNotes({
      title: `${modelName} - Release Notes`,
      service: new ModelReleaseNotesService(modelId, this._domainModelApi),
    });
  }

  openRosettaReleaseNotes() {
    return this._openReleaseNotes({
      title: 'Rosetta - Release Notes',
      service: new RosettaReleaseNotesService(this._releaseNotesApi),
      disableSearch: true,
      markAsSeen: true,
    });
  }

  private _openReleaseNotes(data: ReleaseNotesDialogData) {
    return this.lazyOpenDialog(
      () =>
        import(
          './release-notes-viewer-dialog/release-notes-viewer-dialog.component'
        ).then(m => m.ReleaseNotesViewerDialogComponent),
      data
    ).subscribe();
  }
}
