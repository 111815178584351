import { ITooltipAngularComp } from '@ag-grid-community/angular';
import { ITooltipParams } from '@ag-grid-community/core';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ProjectDto } from '@features/auth/login';
import {
  ProjectLogoComponent,
  Sizes,
} from '@shared/components/project-logo/project-logo.component';
import { RosettaMenuComponent } from '@shared/components/rosetta-menu.component';
import { ModelPipe } from '@shared/pipes';

interface ProjectTooltipParams {
  size: Sizes;
}

@Component({
  standalone: true,
  imports: [AsyncPipe, RosettaMenuComponent, ProjectLogoComponent, ModelPipe],
  selector: 'app-project-tooltip',
  templateUrl: './project-tooltip.component.html',
  styleUrls: ['./project-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectTooltipComponent implements ITooltipAngularComp {
  modelIds!: string[];
  size!: Sizes;

  agInit({
    value,
    size = 'xs',
  }: ITooltipParams<any, ProjectDto[]> & ProjectTooltipParams): void {
    this.size = size;
    this.modelIds = value?.map(({ id }) => id) || [];
  }

  refresh(): boolean {
    return false;
  }
}
