import { Injectable } from '@angular/core';
import { READ_WRITE_SERVER_KEY, STATUS_POLLING_INTERVAL } from '@configs';
import { StatusService } from '@core/services/status.service';
import { StatusResponse, WorkspaceMenuItem } from '@models';
import { ModelInstanceId } from '@models/domain-models';
import { Store } from '@ngrx/store';
import { DomainModelActions } from '@store/domain-models/domain-models.actions';
import { serverStatusResponse } from '@store/server-status/server-status.actions';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import {
  Observable,
  Subscription,
  catchError,
  concatMap,
  first,
  forkJoin,
  mergeAll,
  of,
  switchMap,
  tap,
  timeout,
  timer,
} from 'rxjs';

@Injectable()
export class StatusPollingService {
  constructor(
    private _store: Store,
    private _statusService: StatusService
  ) {}

  private _sub?: Subscription;
  private _domainModelStatusMap = new Map<string, StatusResponse>();

  start(): void {
    if (!this._sub || this._sub.closed) {
      this._sub = timer(0, STATUS_POLLING_INTERVAL)
        .pipe(
          switchMap(() => forkJoin([this._readWrite$(), this._readOnlyList$()]))
        )
        .subscribe();
    }
  }

  stop(): void {
    if (this._sub) {
      this._sub.unsubscribe();
    }
  }

  clear(): void {
    this._domainModelStatusMap.clear();
  }

  // Read Write
  private _readWrite$(): Observable<StatusResponse> {
    return this._statusService.getStatus().pipe(
      tap(status => this._readWriteCheck(status)),
      catchError(error => this._readWriteError(error))
    );
  }

  private _readWriteCheck(statusResponse: StatusResponse): void {
    if (this._checkResponse(READ_WRITE_SERVER_KEY, statusResponse)) {
      this._store.dispatch(
        serverStatusResponse({
          statusResponse,
          key: READ_WRITE_SERVER_KEY,
        })
      );
    }
  }

  private _readWriteError(error: any): Observable<null> {
    this._readWriteCheck(this._createErrorStatus(error));
    return of(null);
  }

  // Read Only
  private _readOnlyList$(): Observable<StatusResponse> {
    return this._store.select(WorkspaceSelectors.selectProjects).pipe(
      first(),
      concatMap(list =>
        list.length < 1
          ? [of(null)]
          : list.map(menuItem => {
              return this._readOnlyStatus$(menuItem);
            })
      ),
      mergeAll()
    );
  }

  private _readOnlyStatus$(
    menuItem: WorkspaceMenuItem
  ): Observable<StatusResponse> {
    return this._statusService.getStatus(menuItem).pipe(
      tap(status => this._readOnlyCheck(menuItem.id.name, status)),
      timeout(STATUS_POLLING_INTERVAL),
      catchError(error => this._readOnlyError(menuItem.id.name, error))
    );
  }

  private _readOnlyCheck(
    workspaceName: string,
    statusResponse: StatusResponse
  ): void {
    if (this._checkResponse(workspaceName, statusResponse)) {
      this._store.dispatch(
        serverStatusResponse({
          key: workspaceName,
          statusResponse,
        })
      );
    }
  }

  private _readOnlyError(
    modelName: ModelInstanceId,
    error: any
  ): Observable<null> {
    this._readOnlyCheck(modelName, this._createErrorStatus(error));
    return of(null);
  }

  // Common
  private _createErrorStatus(error: any): StatusResponse {
    return {
      ReadinessCheck: {
        healthy: false,
        time: 0,
        duration: 0,
        timestamp: '',
        message: error.message || '',
        error: JSON.stringify(error),
      },
    };
  }

  private _checkResponse(
    workspaceName: string | typeof READ_WRITE_SERVER_KEY,
    statusResponse: StatusResponse
  ): boolean {
    let healthChanged = true;

    if (this._domainModelStatusMap.has(workspaceName)) {
      const oldStatus = this._domainModelStatusMap.get(workspaceName);
      healthChanged =
        oldStatus?.ReadinessCheck.healthy !==
        statusResponse.ReadinessCheck.healthy;

      if (healthChanged && statusResponse.ReadinessCheck.healthy) {
        this._store.dispatch(DomainModelActions.load());
      }
    }

    this._domainModelStatusMap.set(workspaceName, statusResponse);

    return healthChanged;
  }
}
