import { APP_DOCUMENTS } from '@configs';
import {
  IDomainModelDetails,
  IModelDocumentDialog,
  UserDocumentMap,
} from '@models';
import { ModelInstanceId } from '@models/domain-models';
import { createSelector } from '@ngrx/store';
import { AuthSelectors } from '@store/selectors';
import { selectDomainModels } from './domain-models.feature';

export const selectAll = createSelector(
  selectDomainModels,
  (models: IDomainModelDetails[]) => models
);

export const selectDomainModel = (modelId: ModelInstanceId) =>
  createSelector(selectDomainModels, (models: IDomainModelDetails[]) =>
    models.find(m => m.id === modelId)
  );

// TODO: Simplify how we're storing model documents to reduce the complexity in the selectors
export const getModelDocumentDialogData = (
  datum: null | ModelInstanceId | [ModelInstanceId, boolean]
) =>
  createSelector(
    AuthSelectors.selectUserDocuments,
    selectAll,
    (
      userDocuments: UserDocumentMap,
      domainModels: IDomainModelDetails[]
    ): IModelDocumentDialog | undefined => {
      let modelId: ModelInstanceId | null;
      let showAll = false;

      if (Array.isArray(datum)) {
        modelId = datum[0];
        showAll = datum[1];
      } else {
        modelId = datum;
      }

      const domainModel = domainModels.find(d => d.id === modelId);

      if (!domainModel) {
        return undefined;
      }

      const documentList = Object.values(domainModel.documents);

      return {
        isFirst: documentList.some(documents =>
          documents.some(
            doc =>
              !userDocuments[doc.modelId] ||
              userDocuments[doc.modelId][doc.documentId] === undefined
          )
        ),
        modelId,
        modelName: domainModel.name,
        documents: documentList
          .map(documents =>
            documents
              .filter(
                doc =>
                  showAll ||
                  !userDocuments[doc.modelId] ||
                  !userDocuments[doc.modelId][doc.documentId]
              )
              .map(doc => {
                const documentDomainModel = domainModels.find(
                  d => d.id === doc.modelId
                );

                const getDocumentLink = (
                  documentName: string,
                  parent = APP_DOCUMENTS
                ): string => `/documents/${parent}/${documentName}`;

                return {
                  ...doc,
                  documentLink: getDocumentLink(doc.documentId, doc.modelId),
                  modelName: documentDomainModel?.name || doc.modelId,
                  privacyStatementLink:
                    documentDomainModel?.privacyStatementLink,
                };
              })
          )
          .flat(),
      };
    }
  );
