import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  TemplateRef,
  booleanAttribute,
  contentChild,
  input,
  signal,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { FontsModule } from '@app/fonts/fonts.module';
import { LetDirective } from '@ngrx/component';
import { InOutAnimation } from '@shared/animations';
import { naturalCompare } from '@utils/array-utils';
import { groupBy } from 'lodash-es';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { Observable, OperatorFunction, filter, map, tap } from 'rxjs';
import { RosettaSelectorOptionsDirective } from './rosetta-selector-options.directive';
import { RosettaSelectorTriggerDirective } from './rosetta-selector-trigger.directive';
import {
  IRosettaSelector,
  IRosettaSelectorData,
} from './rosetta-selector.model';

type RosettaSelectorGroup<TData> = {
  label: string;
  options: TData[];
}[];

export interface RosettaGroupOptions {
  groupBy: string;
  getGroupLabel?: (groupByResult: string) => string;
}

@Component({
  selector: 'app-rosetta-selector',
  standalone: true,
  imports: [
    AsyncPipe,
    FontsModule,
    LetDirective,
    MatSelectModule,
    NgTemplateOutlet,
    ReactiveFormsModule,
    TourMatMenuModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [InOutAnimation],
  templateUrl: './rosetta-selector.component.html',
  styles: ``,
  host: {
    class: 'rosetta-selector',
    '[class]': 'selectorClass()',
  },
})
export class RosettaSelectorComponent<TData = any> implements OnInit {
  constructor(private _destroyRef: DestroyRef) {}

  selector = input.required<IRosettaSelector<TData>>();
  autoSelect = input(false, { transform: booleanAttribute });
  selectorClass = input<string>();
  groupOptions = input<RosettaGroupOptions>();

  $hasOptions = signal(false);

  matSelectComp = viewChild(MatSelect);
  triggerTemplate = contentChild(RosettaSelectorTriggerDirective, {
    read: TemplateRef,
  });
  optionTemplate = contentChild(RosettaSelectorOptionsDirective, {
    read: TemplateRef,
  });

  groupedOptions$: Observable<
    RosettaSelectorGroup<IRosettaSelectorData<TData>>
  >;

  control = new FormControl<string>({ value: null, disabled: true });

  ngOnInit(): void {
    this._selectorListeners();
    this.groupedOptions$ = this.selector().options$.pipe(
      tap(options => this._updateOptions(options)),
      this._mapOptionsToGroups()
    );
  }

  onOpenChanged(isOpen: boolean): void {
    this.selector().onToggle(isOpen);
  }

  onValueChanged(value: string): void {
    this.selector().updateSelection(value);
  }

  close(): void {
    this.matSelectComp().close();
  }

  private _selectorListeners(): void {
    this.selector()
      .selectedId$.pipe(
        filter(selectedId => selectedId !== this.control.value),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe(selectedId => this.control.setValue(selectedId));
  }

  private _updateOptions(options: IRosettaSelectorData<TData>[]): void {
    requestAnimationFrame(() => {
      this._updateFormControl(options);
      if (this.autoSelect()) {
        this._autoSelectOptionOrOpen(options);
      }
    });
  }

  private _updateFormControl(options: IRosettaSelectorData<TData>[]): void {
    this.$hasOptions.set(options.length > 0);

    if (this.$hasOptions()) {
      this.control.enable();
      this.control.setValue(this.selector().value);
    } else {
      this.control.disable();
      this.control.reset();
    }
  }

  private _autoSelectOptionOrOpen(
    options: IRosettaSelectorData<TData>[]
  ): void {
    if (this.selector().$selectedValue() || !this.matSelectComp()) {
      return;
    }

    const comp = this.matSelectComp();
    if (options.length === 1) {
      const [{ value }] = options;
      this.selector().updateSelection(value);
      this.control.setValue(value);
      comp.focus();
    } else {
      comp.focus();
      comp.open();
    }
  }

  private _mapOptionsToGroups(): OperatorFunction<
    IRosettaSelectorData<TData>[],
    RosettaSelectorGroup<IRosettaSelectorData<TData>>
  > {
    return map(ungroupedOptions => {
      const groupOptions = this.groupOptions();

      if (!groupOptions) {
        return [{ label: '', options: ungroupedOptions }];
      }

      const groups = groupBy(ungroupedOptions, `data.${groupOptions.groupBy}`);
      const groupEntries = Object.entries(groups);

      if (groupEntries.length === 1) {
        return [{ label: '', options: ungroupedOptions }];
      }

      return groupEntries
        .sort(([a], [b]) => naturalCompare(a, b))
        .map(([label, options]) => ({
          label: groupOptions.getGroupLabel
            ? groupOptions.getGroupLabel(label)
            : label,
          options,
        }));
    });
  }
}
