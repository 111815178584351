import { IDomainModelDetails } from '@models';
import { createFeature, createReducer, on } from '@ngrx/store';
import { DomainModelActions as actions } from './domain-models.actions';

export interface State {
  domainModels: IDomainModelDetails[];
  loading: boolean;
  errorMessage: string | null;
}

export const initialState: State = {
  domainModels: [],
  loading: false,
  errorMessage: null,
};

export const domainModelFeature = createFeature({
  name: 'domainModels',
  reducer: createReducer(
    initialState,
    on(actions.load, state => ({
      ...state,
      errorMessage: null,
      loading: true,
    })),
    on(actions.loadSuccess, (state, { domainModels }) => ({
      ...state,
      loading: false,
      domainModels,
    })),
    on(actions.update, (state, { domainModel }) => ({
      ...state,
      loading: false,
      domainModels: state.domainModels.map(dm =>
        dm.id === domainModel.id ? domainModel : dm
      ),
    })),
    on(actions.loadFailure, (state, { errorMessage }) => ({
      ...state,
      loading: false,
      errorMessage,
    })),
    on(actions.resetAll, () => initialState)
  ),
});

export const {
  name,
  reducer,
  selectLoading,
  selectDomainModels,
  selectDomainModelsState,
} = domainModelFeature;
