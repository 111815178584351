import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IRosettaConfig, ROSETTA_CONFIG } from '@configs';
import { ReleaseInfoDto } from '@models/dto';
import { PagedResultsDto } from '@models/dto/paged-results.dto';
import { QueryParamFilters } from '@models/query-param-filters';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReleaseNotesApiService {
  constructor(
    private _http: HttpClient,
    @Inject(ROSETTA_CONFIG) private _config: IRosettaConfig
  ) {}

  getReleaseNotes(
    filters: QueryParamFilters
  ): Observable<PagedResultsDto<ReleaseInfoDto>> {
    return this._http.get<PagedResultsDto<ReleaseInfoDto>>(
      `${this._config.resourcePaths.releases}/notes?${filters.queryString()}`
    );
  }

  setRecentReleaseSeen(): Observable<boolean> {
    return this._http.post<boolean>(
      `${this._config.resourcePaths.releases}/set-user-last-seen`,
      {}
    );
  }

  showReleaseNotesBanner(): Observable<boolean> {
    return this._http.get<boolean>(
      `${this._config.resourcePaths.releases}/show-new-release-banner`
    );
  }

  getLastSeenVersion(): Observable<string> {
    return this._http.get(
      `${this._config.resourcePaths.releases}/last-seen-version`,
      {
        responseType: 'text',
      }
    );
  }
}
