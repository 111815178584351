import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
  signal,
} from '@angular/core';
import { FontsModule } from '@app/fonts/fonts.module';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { HEX, WorkspaceType, WorkspaceTypeIconMap } from '@models';
import { useDarkForeground } from '@utils/string-utils';

@Component({
  standalone: true,
  imports: [FontsModule],
  selector: 'app-project-type',
  templateUrl: './project-type.component.html',
  styleUrls: ['./project-type.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[style.color]': 'styleColor()',
    '[style.background]': 'styleBackground()',
    '[class.circle]': '!iconClass',
  },
})
export class ProjectTypeComponent {
  iconConfig: IconProp = ['fas', 'building'];
  iconClass = true;

  styleColor = signal<string>(null);
  styleBackground = signal<string>(null);

  @Input()
  set color(value: HEX) {
    if (!value) {
      return;
    }

    this.iconClass = false;
    this.styleColor.set(
      useDarkForeground(value) ? 'rgba(0,0,0, 0.60)' : 'rgba(255,255,255, 0.75)'
    );
    this.styleBackground.set(value);
  }

  @Input() size?: SizeProp;
  @Input() set data(workspaceType: WorkspaceType) {
    this.iconConfig = WorkspaceTypeIconMap[workspaceType];
  }
}
