<ng-template #iconTmpl>
  @if (iconData) {
    <fa-icon
      [fixedWidth]="true"
      [icon]="iconData.icon"
      [style.color]="iconData.color"
      [matTooltip]="iconData.tooltip"
      size="sm"
      class="mr-4 upgrade-icon-small"
    />
  }
</ng-template>

<small class="mat-small">
  {{ modelId }} / {{ modelInfo?.modelVersion }}

  @if (showWarning) {
    @if (fullMessage) {
      <span class="upgrade-icon-full warn-bg">
        <ng-container [ngTemplateOutlet]="iconTmpl" />
        <span class="font-weight-bold">{{ fullMessage }}</span>
      </span>
    } @else {
      @if (iconData) {
        <fa-icon
          [fixedWidth]="true"
          [icon]="iconData.icon"
          [style.color]="iconData.color"
          [matTooltip]="iconData.tooltip"
          size="sm"
          class="mr-4 upgrade-icon-small"
        />
      }
    }
  }
</small>
