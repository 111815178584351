import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { RosettaTourTemplateComponent } from '@shared/modules/rosetta-tour/rosetta-tour-template.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, RosettaTourTemplateComponent],
  template: ` <app-rosetta-tour-template />
    <router-outlet />`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        height: 100%;
      }
    `,
  ],
})
export class AppComponent {}
