import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  TemplateRef,
  contentChild,
  input,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { FontsModule } from '@app/fonts/fonts.module';
import { MoreOptionsMenuItem } from './more-options-menu.model';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';

// TODO: Rename component to RosettaMenuComponent once existing component has been removed
@Component({
  selector: 'app-more-options-menu',
  standalone: true,
  imports: [
    FontsModule,
    MatButtonModule,
    MatMenuModule,
    NgTemplateOutlet,
    TourMatMenuModule,
  ],
  templateUrl: './more-options-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoreOptionsMenuComponent {
  menuItems = input.required<MoreOptionsMenuItem[]>();
  mainTemplate = contentChild<TemplateRef<void>>(TemplateRef);
}
