import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  ApplicationConfig,
  LOCALE_ID,
  importProvidersFrom,
} from '@angular/core';
import { LOCALE } from '@configs';
import { httpInterceptorProviders } from '@core/interceptors';
import { CoreModule } from '@core/modules';
import { provideEnvironmentProviders } from '@env/environment';
import { provideAppRoutingConfig } from './app.routing';

export const AppConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(CoreModule),
    provideEnvironmentProviders(),
    provideAppRoutingConfig(),
    provideHttpClient(withInterceptorsFromDi()),
    httpInterceptorProviders,
    { provide: LOCALE_ID, useValue: LOCALE },
  ],
};
