import { IDomainModelDetails, IModelDocument } from '@models';
import { ModelInstanceId } from '@models/domain-models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const DomainModelActions = createActionGroup({
  source: 'Domain Model',
  events: {
    load: emptyProps(),
    loadSuccess: props<{ domainModels: IDomainModelDetails[] }>(),
    loadFailure: props<{ errorMessage: string }>(),
    update: props<{ domainModel: IDomainModelDetails }>(),
    acceptModelDocuments: props<{
      modelId: ModelInstanceId;
      documents: IModelDocument[];
    }>(),
    resetAll: emptyProps(),
  },
});
