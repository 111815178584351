import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as projectReducer from './projects.reducer';

export const selectProjectFeature = createFeatureSelector<projectReducer.State>(
  projectReducer.projectsFeatureKey
);

export const selectLoading = createSelector(
  selectProjectFeature,
  state => state.loading
);

export const selectProjects = createSelector(
  selectProjectFeature,
  state => state.data
);
