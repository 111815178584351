@if (logoName() | modelLogo | async; as url) {
  <div class="wrapper wrapper__logo">
    <img [src]="url" loading="lazy" />
  </div>
} @else {
  <div
    class="wrapper wrapper__shortname"
    [style.color]="styleContrastColor()"
    [style.backgroundColor]="styleColor()"
  >
    <h3 class="text-ellipsis">{{ data()?.shortname || 'N/A' }}</h3>
  </div>
}
