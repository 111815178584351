import { PagedResultsDto } from '@models/dto/paged-results.dto';
import { ReleaseNote } from '@models';
import { ReleaseInfoDto } from '@models/dto';
import { OperatorFunction, map } from 'rxjs';

export const fromPagedReleaseNotesDTO = (
  lastSeenVersion?: string
): OperatorFunction<
  PagedResultsDto<ReleaseInfoDto>,
  PagedResultsDto<ReleaseNote>
> => {
  return map(pagedDto => {
    const releases = pagedDto.results.map(ReleaseNoteMapper(lastSeenVersion));
    return {
      results: releases,
      total: pagedDto.total,
    };
  });
};

export const ReleaseNoteMapper = (lastSeenVersion?: string) => {
  let newNote = true;
  return (note: ReleaseInfoDto): ReleaseNote => {
    if (!lastSeenVersion || (newNote && note.tag === lastSeenVersion)) {
      newNote = false;
    }
    return {
      bodyAsHtml: note.bodyAsHtml,
      publishedAt: note.publishedAt,
      tag: note.tag,
      new: newNote,
    };
  };
};
