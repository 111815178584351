import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
  computed,
  input,
  output,
} from '@angular/core';
import { COLOR_DARK } from '@configs';
import { IDomainModelDetails } from '@models';
import { ModelLogoPipe } from '@shared/pipes/model-logo.pipe';
import { contrast } from '@utils/string-utils';

export type Sizes = 'xs' | 'sm' | 'md' | 'lg';

@Component({
  standalone: true,
  imports: [AsyncPipe, ModelLogoPipe],
  selector: 'app-project-logo',
  templateUrl: './project-logo.component.html',
  styleUrls: ['./project-logo.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'project-logo',
    '[class]': 'classList()',
    '[style.borderColor]': 'styleColor()',
  },
})
export class ProjectLogoComponent {
  removeAction = output<IDomainModelDetails>();

  data = input.required<IDomainModelDetails>();
  size = input<Sizes>('md');

  classList = computed(() => {
    const size = this.size();
    return this._setSize(size);
  });
  styleColor = computed(() => {
    const data = this.data();
    return data?.primaryColor || COLOR_DARK;
  });
  styleContrastColor = computed(() => {
    const color = this.styleColor();
    return contrast(color);
  });
  logoName = computed<string | undefined>(() => {
    const data = this.data();
    const size = this.size();
    if (size !== 'xs') {
      return data?.id;
    }
    return undefined;
  });

  private _setSize(size: Sizes): string[] {
    const sizeClassMap: Record<Sizes, string> = {
      xs: 'tile-x-small',
      sm: 'tile-small',
      md: undefined,
      lg: 'tile-large',
    };
    return sizeClassMap[size] ? [sizeClassMap[size]] : [];
  }
}
