import { ReleaseNotesApiService } from '@core/services/release-notes/release-notes-api.service';
import { ReleaseNote } from '@models';
import { QueryParamFilters } from '@models/query-param-filters';
import { Observable, map, zip } from 'rxjs';
import { PagedResultsDto } from '../../../models/dto/paged-results.dto';
import { fromPagedReleaseNotesDTO } from '../mapper/release-note.mapper';
import { IReleaseNotesService } from './release-notes-service';

export class RosettaReleaseNotesService implements IReleaseNotesService {
  constructor(private _releaseNotesApi: ReleaseNotesApiService) {}

  private _cachedLastSeenVersion: string;

  getReleaseNotes(
    filters: QueryParamFilters
  ): Observable<PagedResultsDto<ReleaseNote>> {
    if (!this._cachedLastSeenVersion) {
      return zip(
        this._releaseNotesApi.getReleaseNotes(filters),
        this._releaseNotesApi.getLastSeenVersion()
      ).pipe(
        map(([notes, lastSeenVersion]) => {
          this._cachedLastSeenVersion = lastSeenVersion;
          return notes;
        }),
        fromPagedReleaseNotesDTO(this._cachedLastSeenVersion)
      );
    }
    return this._releaseNotesApi
      .getReleaseNotes(filters)
      .pipe(fromPagedReleaseNotesDTO(this._cachedLastSeenVersion));
  }

  setRecentReleaseSeen(): Observable<boolean> {
    this._cachedLastSeenVersion = undefined;
    return this._releaseNotesApi.setRecentReleaseSeen();
  }
}
