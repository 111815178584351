import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { FontsModule } from '@app/fonts/fonts.module';
import { InternalLink } from '@configs';
import { Store } from '@ngrx/store';
import { InfoPanelComponent } from '@shared/components/info-panel/info-panel.component';
import { LinkWithArrowComponent } from '@shared/components/link-with-arrow/link-with-arrow.component';
import { AuthActions } from '@store/.';
import { AuthSelectors } from '@store/selectors';

@Component({
  standalone: true,
  imports: [
    AsyncPipe,
    ReactiveFormsModule,
    RouterModule,
    FontsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    LinkWithArrowComponent,
    InfoPanelComponent,
  ],
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'v2-style-wrapper',
  },
})
export class LoginPageComponent {
  constructor(
    private _fb: UntypedFormBuilder,
    private _store: Store
  ) {}

  readonly internalLink = InternalLink;

  hidePassword = true;
  isLoggingIn$ = this._store.select(AuthSelectors.isLoggingIn);

  loginForm = this._fb.group({
    username: ['', [Validators.required]],
    password: ['', [Validators.required]],
  });

  login() {
    if (this.loginForm.invalid) {
      return;
    }

    this._store.dispatch(
      AuthActions.login({
        userCredentials: {
          userName: this.loginForm.value.username,
          password: this.loginForm.value.password,
        },
      })
    );
  }
}
