import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from '@app/app.component';
import { AppConfig } from '@app/app.config';
import { environment } from '@env/environment';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, AppConfig)
  .then(() => {
    if ('serviceWorker' in navigator && environment.production) {
      navigator.serviceWorker.getRegistrations().then(registrations => {
        for (const registration of registrations) {
          registration.unregister();
        }
      });
    }

    /*
    Prevent file drop globally apart from designated drop zones
    */
    const types = ['drop', 'dragover'] as const;
    types.forEach(type => {
      document.addEventListener(type, event => {
        event.preventDefault();
      });
    });
  })
  // eslint-disable-next-line no-console
  .catch(err => console.error(err));
