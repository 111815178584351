import { InjectionToken, Provider } from '@angular/core';
import { RosettaStepOption } from './rosetta-tour.service';

export const TOUR_STEPS_CONFIG = new InjectionToken<RosettaStepOption>(
  'tourStepsConfig'
);

export const provideTourSteps = (
  ...moduleImports: Promise<{ default: RosettaStepOption[] }>[]
): Provider => ({
  provide: TOUR_STEPS_CONFIG,
  useFactory: async () => {
    const loadedSteps = await Promise.all(
      moduleImports.map(async moduleImport => {
        try {
          const module = await moduleImport;
          return module.default;
        } catch (error) {
          console.error('Failed to load tour steps:', error);
          return [];
        }
      })
    );
    return loadedSteps.flat();
  },
});
