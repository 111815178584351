import { inject } from '@angular/core';
import { CoreDialogService } from '@app/dialogs/core-dialog.service';
import { WORKSPACE_READY } from '@configs';
import { WorkspaceService } from '@core/services';
import {
  NoDialogResultFilter,
  dialogResultOperator,
} from '@core/services/base-defer-dialog.service';
import { LanguageServerService } from '@core/services/language-server.service';
import { ServerStateSocketService } from '@core/services/server-state-socket.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { DomainModelSelectors } from '@store/domain-models';
import { DomainModelActions } from '@store/domain-models/domain-models.actions';
import * as WorkspaceActions from '@store/workspace/actions';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { firstNotNullAndComplete } from '@utils/operators';
import { distinctUntilChanged, first, map, switchMap, take, tap } from 'rxjs';

/*
Check router event if workspace has changed and if it has load workspace
 */
export const loadWorkspace = createEffect(
  (actions$ = inject(Actions), workspaceService = inject(WorkspaceService)) => {
    return actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      map(({ payload }) => payload.routerState.params.workspaceName),
      distinctUntilChanged(),
      tap((name: string | undefined) => {
        if (name) {
          workspaceService.load(name);
        } else {
          workspaceService.close();
        }
      })
    );
  },
  { functional: true, dispatch: false }
);

export const workspaceReady = createEffect(
  (
    actions$ = inject(Actions),
    serverStateSocketService = inject(ServerStateSocketService),
    store = inject(Store)
  ) => {
    const modifiedWorkspaceItems$ = store.select(
      WorkspaceSelectors.selectedModifiedWorkspaceItems
    );

    return actions$.pipe(
      ofType(WorkspaceActions.workspaceReady),
      switchMap(() =>
        modifiedWorkspaceItems$.pipe(
          take(1),
          tap(modifiedItems => {
            const modifiedUris = modifiedItems.map(
              (item: { info: { uri: any } }) => item.info.uri
            );
            serverStateSocketService.send(WORKSPACE_READY, { modifiedUris });
          })
        )
      )
    );
  },
  { functional: true, dispatch: false }
);

export const setWorkspaceItemInEditor$ = createEffect(
  (
    actions$ = inject(Actions),
    languageService = inject(LanguageServerService)
  ) => {
    return actions$.pipe(
      ofType(WorkspaceActions.selectWorkspaceItem),
      tap(({ uri }) => languageService.selectItem(uri))
    );
  },
  { functional: true, dispatch: false }
);

export const switchWorkspace = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) => {
    return actions$.pipe(
      ofType(WorkspaceActions.switchWorkspace),
      switchMap(() =>
        store.select(WorkspaceSelectors.selectWorkspaceInfo).pipe(
          firstNotNullAndComplete(),
          map(info =>
            WorkspaceActions.checkModelDocuments({
              modelId: info.modelId,
              disableClose: true,
            })
          )
        )
      )
    );
  },
  { functional: true }
);

export const checkModelDocuments = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) => {
    return actions$.pipe(
      ofType(WorkspaceActions.checkModelDocuments),
      switchMap(({ modelId, disableClose }) =>
        store
          .select(DomainModelSelectors.getModelDocumentDialogData(modelId))
          .pipe(
            first(modelDocumentData => modelDocumentData !== undefined),
            map(modelDocumentData => {
              const hasDocumentsToAccept =
                modelDocumentData?.documents.length > 0;
              return hasDocumentsToAccept
                ? WorkspaceActions.openModelDocuments({
                    modelDocumentData,
                    disableClose,
                  })
                : WorkspaceActions.checkModelDocumentsSuccess();
            })
          )
      )
    );
  },
  { functional: true }
);

export const openModelDocuments = createEffect(
  (actions$ = inject(Actions), dialogService = inject(CoreDialogService)) => {
    return actions$.pipe(
      ofType(WorkspaceActions.openModelDocuments),
      switchMap(({ modelDocumentData, disableClose }) =>
        dialogService
          .openProjectLicense({
            modelDocumentData,
            disableClose,
            readOnly: false,
          })
          .pipe(
            dialogResultOperator(NoDialogResultFilter),
            map(hasAccepted =>
              hasAccepted
                ? DomainModelActions.acceptModelDocuments({
                    modelId: modelDocumentData.modelId,
                    documents: modelDocumentData.documents,
                  })
                : WorkspaceActions.navigateToWorkspaces()
            )
          )
      )
    );
  },
  { functional: true }
);
