import {
  ELLIPSIS_CHAR,
  PATH_JOIN_CHAR,
  PATH_JOIN_CHAR_WITH_SPACE,
} from '@configs';
import { SampleRowAction } from '@shared/components/transform/models/sample-action.enum';
import { SampleCellState } from '@shared/components/transform/models/sample-cell-state.enum';
import { SampleRowState } from '@shared/components/transform/models/sample-row-state.enum';
import { SampleRow } from '@shared/components/transform/models/sample-row.model';
import { TestPackGridSelection } from '@shared/components/transform/models/test-pack-grid-selection.model';
import { TestPackGrid } from '@shared/components/transform/models/test-pack-grid.model';
import { TransformDTO } from '@shared/components/transform/models/transform-dto.model';
import { multipleExist } from '@utils/array-utils';
import { groupBy } from 'lodash-es';
import { appendIndexesToNodes } from '../../helpers/append-indexes-to-nodes.helper';
import { computeLastUniquePathElementBackwardIndices } from '../../helpers/set-header-name-to-shortest.helper';
import { TransformStatus } from '../sample-row-status.enum';
import { TransformType } from '../transform-type.enum';

export class DataViewerCol {
  constructor(cell: DataViewerCell) {
    this.addCell(cell);
    this.headerName = cell.cell.name;
    this.fieldNameId = cell.fieldNameId;
    this.path = appendIndexesToNodes(cell.path);
    this.toolTip = this.path.join(PATH_JOIN_CHAR_WITH_SPACE);
  }

  cells: DataViewerCell[] = [];
  headerName?: string;
  fieldNameId: string;
  toolTip: string;
  path: string[];
  hasChanges = false;

  get hidden(): boolean {
    return this._hidden;
  }
  set hidden(value: boolean) {
    this._hidden = value;
    this.cells.forEach(cell => (cell.hidden = value));
  }
  private _hidden = false;

  addCell(cell: DataViewerCell): void {
    if (!this.hasChanges) {
      this.hasChanges = cell.hasChanges;
    }

    cell.hidden = this.hidden;
    this.cells.push(cell);
  }
}

export class DataViewerCell {
  constructor(public cell: TransformDTO.SampleCellDTO) {}

  hidden: boolean;

  get cellState(): SampleCellState {
    return this.cell.cellState;
  }

  get fieldNameId(): string {
    return this.cell.columnId;
  }

  get name(): string | undefined {
    return this.cell.name;
  }

  get path(): string[] {
    return this.cell.pathElements;
  }

  get value(): string | undefined {
    return this.cell.outputValue;
  }

  get expectedBaseValue(): string | undefined {
    return this.cell.expectedBaseValue;
  }

  get expectedUpdatedValue(): string | undefined {
    return this.cell.expectedUpdatedValue;
  }

  get hasChanges(): boolean {
    return this.cell.cellState !== SampleCellState.Valid;
  }
}

export class DataViewerRow {
  constructor(dataViewer: DataViewer, row: SampleRow) {
    this.sampleId = row.id;
    this.sampleName = row.name;
    this.sampleRowState = row.state;
    this.sampleRowStatus = row.status;
    this.storedOnClient = row.storedOnClient || false;
    this.diagnostics = row.diagnostics;
    this.errorMessage = row.errorMessage;

    this._createCellMap(row.cells);
    this.testPackGridSelection = dataViewer.testPackGridSelection;
    this.actions = row.actions;

    this.selectable = row.actions.length > 0;
    this.hasChanges = row.state !== SampleRowState.Valid;
    this.cellStates = groupBy(Array.from(this.cellMap.values()), 'cellState');

    if (!dataViewer.canPerformActions && row.actions.length > 0) {
      dataViewer.canPerformActions = true;
    }
  }

  selectable: boolean;
  actions: SampleRowAction[];
  hasChanges: boolean; // Display row when show changes is enabled

  sampleId: string;
  sampleName: string;
  sampleRowState: SampleRowState;
  sampleRowStatus: TransformStatus;
  storedOnClient: boolean;
  diagnostics?: TransformDTO.Diagnostics;
  errorMessage?: string;

  testPackGridSelection: TestPackGridSelection;
  cellStates: Record<string, DataViewerCell[]>;
  cellMap = new Map<string, DataViewerCell>();
  cellList: DataViewerCell[] = [];

  checkAction(...actions: SampleRowAction[]): boolean {
    return multipleExist(this.actions, actions);
  }

  isNotLegacyIngest(): boolean {
    return (
      !this.errorMessage &&
      this.testPackGridSelection.pipelineDef.transformType !==
        TransformType.LegacyIngest
    );
  }

  private _createCellMap(cells: TransformDTO.SampleCellDTO[]): void {
    this.cellList = [];
    cells.forEach(cell => {
      const dataViewerCell = new DataViewerCell(cell);

      // TODO: create a fields ID from its path and a fieldName used to display value in column
      this.cellMap.set(cell.columnId, dataViewerCell);
      this.cellList.push(dataViewerCell);
    });
  }
}

export class DataViewer {
  constructor(testPackGrid: TestPackGrid) {
    this.canDelete = testPackGrid.rows.some(row =>
      row.actions.includes(SampleRowAction.Delete)
    );

    this.testPackGridSelection = {
      pipelineDef: testPackGrid.pipelineDef,
      testPackDef: testPackGrid.testPackDef,
    };
    this.rows = testPackGrid.rows.map(row => new DataViewerRow(this, row));
    this.columns = this._buildColumnsFromRows(this.rows);
    this.tabulatorUnsupported = this.rows.some(
      row => row.sampleRowState === SampleRowState.SkippedTabulator
    );
  }

  rows: DataViewerRow[];
  columns: DataViewerCol[];
  canDelete: boolean;
  testPackGridSelection: TestPackGridSelection;
  canPerformActions = false;
  tabulatorUnsupported = false;

  private _buildColumnsFromRows(
    dataViewerRows: DataViewerRow[]
  ): DataViewerCol[] {
    const columns = new Map<string, DataViewerCol>();
    dataViewerRows.forEach(dataViewerRow => {
      dataViewerRow.cellMap.forEach(dataViewerCell => {
        if (columns.has(dataViewerCell.fieldNameId)) {
          columns.get(dataViewerCell.fieldNameId).addCell(dataViewerCell);
        } else {
          columns.set(
            dataViewerCell.fieldNameId,
            new DataViewerCol(dataViewerCell)
          );
        }
      });
    });

    return setHeaderNameToShortest([...columns.values()]);
  }
}

/*
DataViewerCol helper - placed here to avoid circular dependencies
*/
const truncateMiddleOfPath = (path: string[]): string => {
  if (path.length <= 2) {
    return path.join(PATH_JOIN_CHAR_WITH_SPACE);
  }

  const first = path[0];
  const last = path[path.length - 1];
  return `${first} ${PATH_JOIN_CHAR} ${ELLIPSIS_CHAR} ${PATH_JOIN_CHAR} ${last}`;
};

export const setHeaderNameToShortest = (
  columns: DataViewerCol[]
): DataViewerCol[] => {
  const columnPaths = columns.map(column => {
    if (column.headerName) {
      return [...column.path, column.headerName];
    }
    if (column.path.length === 0) {
      return ['<no name>'];
    }
    return column.path;
  });
  const backwardIndices =
    computeLastUniquePathElementBackwardIndices(columnPaths);
  const headElements = columnPaths.map((p, i) => p.at(-backwardIndices[i]));
  columns.forEach((column, columnIndex) => {
    const backwardIndex = backwardIndices[columnIndex];
    const path = columnPaths[columnIndex];
    const shortPath = path.slice(path.length - 1 - backwardIndex);
    const headElement = headElements[columnIndex];
    const lastElement = path.at(-1);

    if (column.headerName) {
      const shortPathWithoutHeaderName = shortPath.slice(
        0,
        shortPath.length - 1
      );
      if (backwardIndex >= 1) {
        column.headerName = `${shortPathWithoutHeaderName.join(PATH_JOIN_CHAR_WITH_SPACE)}: ${lastElement}`;
        if (backwardIndex >= 3) {
          // Try to truncate
          const isTruncatedUnique = columnPaths.every(
            (p, i) =>
              i === columnIndex ||
              (headElement === headElements[i] && lastElement === p.at(-1))
          );
          if (isTruncatedUnique) {
            column.headerName = `${truncateMiddleOfPath(shortPathWithoutHeaderName)}: ${lastElement}`;
          }
        }
      }
    } else {
      column.headerName = shortPath.join(PATH_JOIN_CHAR_WITH_SPACE);
      if (backwardIndex >= 2) {
        // Try to truncate
        const isTruncatedUnique = columnPaths.every(
          (p, i) =>
            i === columnIndex ||
            (headElement === headElements[i] && lastElement === p.at(-1))
        );
        if (isTruncatedUnique) {
          column.headerName = truncateMiddleOfPath(shortPath);
        }
      }
    }
  });
  return columns;
};
