import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FontsModule } from '@app/fonts/fonts.module';
import { COLOR_DARKEST } from '@configs';
import { WorkspaceCommands } from '@models';
import { Store } from '@ngrx/store';
import { LoadingSpinnerComponent } from '@shared/components/loading-spinner/loading-spinner.component';
import { ProjectTypeComponent } from '@shared/components/project-type/project-type.component';
import { UpgradeIconComponent } from '@shared/components/upgrade-icon/upgrade-icon.component';
import { RosettaOverlayTargetDirective } from '@shared/modules/rosetta-overlay/rosetta-overlay-target.directive';
import { RosettaOverlayComponent } from '@shared/modules/rosetta-overlay/rosetta-overlay.component';
import { DomainModelSelectors } from '@store/domain-models';
import * as WorkspaceActions from '@store/workspace/actions';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { filterNotNulls } from '@utils/operators';
import { combineLatest, map, switchMap } from 'rxjs';
import { WorkspaceDropdownComponent } from '../workspace-dropdown/workspace-dropdown.component';

@Component({
  selector: 'app-workspace-menu',
  standalone: true,
  imports: [
    AsyncPipe,
    FontsModule,
    LoadingSpinnerComponent,
    ProjectTypeComponent,
    RosettaOverlayComponent,
    RosettaOverlayTargetDirective,
    UpgradeIconComponent,
    WorkspaceDropdownComponent,
  ],
  templateUrl: './workspace-menu.component.html',
  styleUrls: ['./workspace-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'v2-style-wrapper',
  },
})
export class WorkspaceMenuComponent {
  constructor(private _store: Store) {}

  isLoading$ = this._store.select(WorkspaceSelectors.isWorkspaceLoading);
  workspaceInfo$ = this._store
    .select(WorkspaceSelectors.selectWorkspaceInfo)
    .pipe(filterNotNulls());
  model$ = this.workspaceInfo$.pipe(
    switchMap(info =>
      this._store.select(DomainModelSelectors.selectDomainModel(info?.modelId))
    )
  );
  modelColor$ = combineLatest([
    this.isLoading$,
    this.model$.pipe(map(model => model?.primaryColor)),
  ]).pipe(
    map(([isLoading, primaryColor]) => {
      if (isLoading || !primaryColor) {
        return COLOR_DARKEST;
      }
      return primaryColor;
    })
  );

  onAction(action: WorkspaceCommands): void {
    this._store.dispatch(WorkspaceActions.workspaceAction({ action }));
  }
}
