import { IDomainModelDetails } from '@models';
import { Store } from '@ngrx/store';
import { OperatorFunction, first, map, switchMap } from 'rxjs';
import { DomainModelSelectors } from '.';

export const acceptDomainModelAndMarkAsAccepted = (
  // eslint-disable-next-line @ngrx/use-consistent-global-store-name
  store: Store,
  modelId: string
): OperatorFunction<any, IDomainModelDetails> =>
  switchMap(() =>
    store.select(DomainModelSelectors.selectDomainModel(modelId)).pipe(
      first(),
      map(domainModel => ({
        ...domainModel,
        accepted: true,
      }))
    )
  );
