import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { CoreDialogService } from '@app/dialogs/core-dialog.service';
import { FontsModule } from '@app/fonts/fonts.module';
import { ExternalLink } from '@configs';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { TextBadgeComponent } from '@shared/components/text-badge/text-badge.component';
import { AppSelectors } from '@store/selectors';

@Component({
  standalone: true,
  imports: [
    LetDirective,
    MatButtonModule,
    MatMenuModule,
    FontsModule,
    TextBadgeComponent,
  ],
  selector: 'app-help-menu',
  templateUrl: './help-menu.component.html',
  styleUrls: ['./help-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpMenuComponent {
  constructor(
    private _store: Store,
    private _coreDialogService: CoreDialogService
  ) {}

  readonly externalLink = ExternalLink;
  newReleaseNotes$ = this._store.select(AppSelectors.newReleaseNotes);

  openIssueTracker(): void {
    this._coreDialogService.openIssueTracker();
  }

  openReleaseNotes(): void {
    this._coreDialogService.openRosettaReleaseNotes();
  }
}
