import { DomainModelApiService } from '@core/services';
import { ReleaseNote } from '@models';
import { ModelInstanceId } from '@models/domain-models';
import { PagedResultsDto } from '@models/dto/paged-results.dto';
import { QueryParamFilters } from '@models/query-param-filters';
import { Observable } from 'rxjs';
import { fromPagedReleaseNotesDTO } from '../mapper/release-note.mapper';
import { IReleaseNotesService } from './release-notes-service';

export class ModelReleaseNotesService implements IReleaseNotesService {
  constructor(
    private _modelId: ModelInstanceId,
    private _domainModelApi: DomainModelApiService
  ) {}
  getReleaseNotes(
    filter: QueryParamFilters
  ): Observable<PagedResultsDto<ReleaseNote>> {
    return this._domainModelApi
      .getModelReleaseNotes(this._modelId, filter)
      .pipe(fromPagedReleaseNotesDTO());
  }

  setRecentReleaseSeen(): Observable<boolean> {
    throw new Error('Method not implemented.');
  }
}
