import { AppThemes, SnackBarConfig } from '@models';
import {
  createAction,
  createActionGroup,
  emptyProps,
  props,
} from '@ngrx/store';

export const showBasicErrorMsg = createAction(
  '[App] Show Error Message',
  props<{ message: string }>()
);

export const showBasicWarningMsg = createAction(
  '[App] Show Warning Message',
  props<{ message: string }>()
);

export const showBasicSuccessMsg = createAction(
  '[App] Show Success Message',
  props<{ message: string }>()
);

export const showDisconnectMsg = createAction(
  '[App] Show Disconnect Message',
  (payload?: { errorMessage: string }) => payload
);

export const appRefreshUpdate = createActionGroup({
  source: '[App Refresh Update]',
  events: {
    check: emptyProps(),
    refresh: emptyProps(),
    required: emptyProps(),
  },
});

export const disconnectFromWorkspace = createAction(
  '[App] Disconnect From Workspace',
  (payload?: { errorMessage: string }) => payload
);

export const showErrorMsg = createAction(
  '[App] Show Error Message With Action',
  props<{ config: SnackBarConfig }>()
);

export const closeAllOverlays = createAction('[App] Close All Open Overlays');

export const getAppVersion = createAction('[App API] Get App Version');
export const getAppVersionSuccess = createAction(
  '[App API] Get App Version Success',
  props<{ version: string }>()
);

export const updateTheme = createAction(
  '[App] Update Theme',
  props<{ theme: AppThemes | null }>()
);
export const toggleTheme = createAction('[App] Toggle Theme');
export const togglePresenting = createAction('[App] Presenting');

export const monacoReady = createAction('[App] Monaco Ready');

export const checkReleaseNotesSeen = createAction(
  '[App] Check Release Notes Seen',
  props<{ seen: boolean }>()
);
