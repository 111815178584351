import { HttpErrorResponse } from '@angular/common/http';
import { WorkspaceItemDiagnostic } from '@features/workspace/models/rosetta-core.model';
import {
  CreateWorkspaceRequest,
  IDomainModelDetails,
  IModelDocumentDialog,
  SymbolIdentifier,
  Workspace,
  WorkspaceCommands,
  WorkspaceId,
  WorkspaceItem,
  WorkspaceItemState,
  WorkspaceMenuItem,
} from '@models';
import { ModelInstanceId } from '@models/domain-models';
import { createAction, props } from '@ngrx/store';
import { ConnectionState } from '@workspace-design/textual/models/rosetta-editor.model';

export const navigateToWorkspaces = createAction(
  '[Workspace] Navigate To Workspaces'
);
export const switchWorkspace = createAction('[Workspace] Switch Workspace');
export const switchWorkspaceSuccess = createAction(
  '[Workspace] Switch Workspace Success'
);
export const switchWorkspaceFailure = createAction(
  '[Workspace] Switch Workspace Failure'
);
export const workspaceExistsError = createAction(
  '[Workspace] Workspace Exists Error',
  props<{ workspaceName: string }>()
);

export const multipleConnectionAttemptFailure = createAction(
  '[Workspace] Multiple Connection Attempt Failure'
);

export const watchWorkspaceItems = createAction(
  '[Workspace] Watch Workspace Items'
);
export const saveWorkspace = createAction('[Workspace] Save Workspace');
export const saveWorkspaceItem = createAction(
  '[Workspace] Save Workspace Item',
  props<{ uri: string }>()
);

export const saveWorkspaceSuccess = createAction(
  '[Workspace] Save Workspace Success',
  props<{
    workspaceItems: WorkspaceItem[];
    domainModels: IDomainModelDetails[];
  }>()
);
export const saveWorkspaceFailure = createAction(
  '[Workspace] Save Workspace Failure'
);

export const addWorkspaceItem = createAction(
  '[Workspace] Add Workspace Item',
  props<{ workspaceItem: WorkspaceItemState }>()
);

export const updateWorkspaceItemContent = createAction(
  '[Workspace] Update Workspace Item Content',
  props<{ items: { uri: string; contents: string }[] }>()
);

export const revertWorkspaceItem = createAction(
  '[Workspace] Revert Workspace Item',
  props<{ uri: string; name: string }>()
);

export const revertWorkspaceItemConfirmed = createAction(
  '[Workspace] Revert Workspace Item Confirmed',
  props<{ uri: string }>()
);

export const confirmForkFile = createAction(
  '[Workspace] Confirm Workspace Item Forked',
  props<{ uri: string }>()
);

export const selectWorkspaceItem = createAction(
  '[Workspace] Select Workspace Item',
  props<{
    uri: string;
    openDiffEditor?: boolean;
  }>()
);

export const closeWorkspace = createAction(
  '[Workspace] close Workspace',
  props<{ unsetLastWorkspace: boolean }>()
);
export const closeWorkspaceSuccess = createAction(
  '[Workspace] close Workspace Success'
);

export const clearServices = createAction('[Workspace] clear services');

export const loadWorkspaceItems = createAction(
  '[Workspace] Load Workspace Items',
  props<{ workspace: Workspace }>()
);

export const openModelDocuments = createAction(
  '[Workspace] Open Model Documents To Accept',
  props<{
    modelDocumentData: IModelDocumentDialog;
    disableClose: boolean;
  }>()
);
export const checkModelDocuments = createAction(
  '[Workspace] Check Model Documents To Accept',
  props<{
    modelId: ModelInstanceId | null;
    disableClose: boolean;
  }>()
);
export const viewAcceptedModelDocuments = createAction(
  '[Workspace] View Accepted Model Documents',
  props<{
    modelId: ModelInstanceId;
  }>()
);
export const checkModelDocumentsSuccess = createAction(
  '[Workspace] Check Model Documents To Accept Success'
);

export const refreshProjectsCache = createAction(
  '[Workspace] Refresh Projects Cache'
);
export const loadProjects = createAction('[Workspace] Load Projects');
export const loadProjectsSuccess = createAction(
  '[Workspace] Load Models Success',
  props<{ modelItems: WorkspaceMenuItem[] }>()
);

export const loadWorkspaces = createAction('[Workspace] Load Workspaces');
export const loadWorkspacesSuccess = createAction(
  '[Workspace] Load Workspaces Success',
  props<{ workspaceMenuItems: WorkspaceMenuItem[] }>()
);
export const loadWorkspacesFailure = createAction(
  '[Workspace] Load Workspaces Failure'
);

export const deleteWorkspace = createAction(
  '[Workspace] Delete Workspace',
  props<{ workspaceId: WorkspaceId }>()
);
export const deleteWorkspaceSuccess = createAction(
  '[Workspace] Delete Workspace Success',
  props<{ workspaceId: WorkspaceId }>()
);
export const deleteWorkspaceFailure = createAction(
  '[Workspace] Delete Workspace Failure',
  props<{ workspaceId: WorkspaceId }>()
);

export const loadLastWorkspace = createAction(
  '[Workspace] load last workspace'
);
export const loadLastWorkspaceSuccess = createAction(
  '[Workspace] load last workspace Success'
);
export const loadLastWorkspaceFailure = createAction(
  '[Workspace] load last workspace Failure'
);

export const createNewWorkspace = createAction(
  '[Workspace] Create New Workspace',
  props<{ request: CreateWorkspaceRequest }>()
);

export const createNewWorkspaceSuccess = createAction(
  '[Workspace] Create New Workspace Success',
  props<{ workspaceMenuItem: WorkspaceMenuItem }>()
);
export const createNewWorkspaceFailure = createAction(
  '[Workspace] Create New Workspace Failure',
  props<{ error: HttpErrorResponse }>()
);

export const workspaceAction = createAction(
  '[Workspace] Workspace action',
  props<{ action: WorkspaceCommands }>()
);

export const selectFirstWorkspaceItemWithChanges = createAction(
  '[Workspace] Select First Workspace Item With Changes'
);

export const updateWorkspaceLoadingState = createAction(
  '[Workspace] Update Workspace Loading State',
  props<{ state: ConnectionState }>()
);

export const updateDiagnostics = createAction(
  '[Workspace] Update Diagnostics',
  props<{ diagnostics: WorkspaceItemDiagnostic[] }>()
);

export const workspaceReady = createAction('[Workspace] Workspace Ready');

export const websocketError = createAction(
  '[Workspace] Websocket Error',
  (rollbarError?: string) => ({ rollbarError })
);
export const websocketClose = createAction('[Workspace] Websocket Close');

export const invalidCompiledPojoError = createAction(
  '[Workspace] Invalid Compiled Pojo Error'
);

export const recompileInvalidPojo = createAction(
  '[Workspace] Recompile Invalid Pojo'
);

export const cleanupSessionState = createAction(
  '[Workspace] Cleanup Session State'
);

export const saveWorkspaceThenCloseWorkspace = createAction(
  '[Workspace] Save Workspace and then Close Workspace',
  props<{ unsetLastWorkspace: boolean; saveBeforeClose: boolean }>()
);

export const goToSymbol = createAction(
  '[Workspace] Go To Symbol',
  props<{ classIdentification: SymbolIdentifier }>()
);

export const goToSymbolFailure = createAction(
  '[Workspace] Go To Symbol Failure'
);
