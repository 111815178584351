import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { FontsModule } from '@app/fonts/fonts.module';
import {
  IDomainModelDetails,
  WorkspaceCommands,
  WorkspaceMenuItem,
} from '@models';
import { ModelInstanceId } from '@models/domain-models';
import { WA_WINDOW } from '@ng-web-apis/common';
import { Store } from '@ngrx/store';
import { DevBannerComponent } from '@shared/components/dev-banner.component';
import { ProjectCardMenuComponent } from '@shared/components/project-card-menu/project-card-menu.component';
import { ProjectLogoComponent } from '@shared/components/project-logo/project-logo.component';
import { WorkspaceRecentListComponent } from '@shared/components/workspace-recent-list/workspace-recent-list.component';
import { ShowSpinnerDirective } from '@shared/directives/show-spinner.directive';
import { DomainModelSelectors } from '@store/domain-models';
import * as WorkspaceActions from '@store/workspace/actions';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { BehaviorSubject, distinctUntilChanged, first, map } from 'rxjs';
import { ProjectTypeTextComponent } from '../project-type-text/project-type-text.component';

@Component({
  selector: 'app-workspace-dropdown',
  standalone: true,
  imports: [
    AsyncPipe,
    DevBannerComponent,
    FontsModule,
    MatButtonModule,
    ProjectCardMenuComponent,
    ProjectLogoComponent,
    ProjectTypeTextComponent,
    WorkspaceRecentListComponent,
    ShowSpinnerDirective,
  ],
  templateUrl: './workspace-dropdown.component.html',
  styleUrls: ['./workspace-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'mat-elevation-z5',
  },
})
export class WorkspaceDropdownComponent {
  constructor(
    private _store: Store,
    @Inject(WA_WINDOW) private _window: Window
  ) {}

  @Output() action = new EventEmitter<WorkspaceCommands>();
  @Output() closeDropdown = new EventEmitter<void>();

  @Input()
  set data(workspaceData: WorkspaceMenuItem | undefined) {
    if (workspaceData) {
      this._workspaceData = workspaceData;
      this.setModel(workspaceData.modelId);
    }
  }
  get data(): WorkspaceMenuItem | undefined {
    return this._workspaceData;
  }

  private _workspaceData: WorkspaceMenuItem | undefined;

  workspaceReady$ = this._store
    .select(WorkspaceSelectors.isWorkspaceLoading)
    .pipe(map(workspaceLoading => workspaceLoading === false));
  model$ = new BehaviorSubject<IDomainModelDetails | undefined>(undefined);
  noModifiedFiles$ = this._store
    .select(WorkspaceSelectors.selectedModifiedWorkspaceItems)
    .pipe(
      map(itemStates => itemStates.length === 0),
      distinctUntilChanged()
    );

  get mode(): string {
    return this.data?.readOnly ? 'Read Only' : 'Editable';
  }

  setModel(modelId: ModelInstanceId): void {
    this._store
      .select(DomainModelSelectors.selectDomainModel(modelId))
      .pipe(first())
      .subscribe(model => this.model$.next(model));
  }

  gotoWorkspaceManager(): void {
    this._store.dispatch(WorkspaceActions.navigateToWorkspaces());
  }

  openInGithub(url: string): void {
    this._window.open(url, '_blank');
  }
}
