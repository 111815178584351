import { Injectable } from '@angular/core';
import { TOUR_CONFIG } from '@app/config/tour.config';
import { StorageKey } from '@configs';
import { LocalStorageService } from '@core/services';
import { TourState } from 'ngx-ui-tour-core';
import { TourService } from 'ngx-ui-tour-md-menu';
import { IMdStepOption } from 'ngx-ui-tour-md-menu/lib/step-option.interface';
import { Subscription, delay, first, takeUntil } from 'rxjs';

export interface RosettaTourState<T = Set<string>> {
  tourVersion: number | null;
  seenStepIds: T;
}

export type RosettaStepOption = IMdStepOption & {
  anchorCheckOverrideId?: string;
};

@Injectable()
export class RosettaTourService {
  constructor(
    private _storage: LocalStorageService,
    private _tourService: TourService
  ) {}

  private _sub: Subscription;
  private _queuedSteps: RosettaStepOption[][] = [];
  private get _disabled(): boolean {
    return window.__DISABLE_ROSETTA_TOUR__;
  }

  loadTourSteps(steps: RosettaStepOption[]): void {
    if (this._disabled) {
      return;
    }

    steps = this._removeSeenSteps(steps);
    this._setupPopoverClass(steps);

    if (this.hasStarted()) {
      this._queueSteps(steps);
    } else {
      this._initializeTour(steps);
    }
  }

  start(): void {
    if (!this._disabled && !this.hasStarted()) {
      this._setup();
      this._tourService.start();
    }
  }

  stop(): void {
    this._tourService.steps = [];
    this._tourService.end();
  }

  hasStarted(): boolean {
    return this._tourService.getStatus() === TourState.ON;
  }

  private _startListenerForEnd(): void {
    if (this._sub) {
      this._sub.unsubscribe();
    }
    this._sub = this._tourService.end$
      .pipe(first(), delay(TOUR_CONFIG.delayBetweenTours))
      .subscribe(() => {
        if (this._queuedSteps.length > 0) {
          const queuedSteps = this._queuedSteps.pop();
          this._initializeTour(queuedSteps);
          this.start();
        }
      });
  }

  private _initializeTour(steps: RosettaStepOption[]): void {
    this._tourService.setDefaults({
      duplicateAnchorHandling: 'registerLast',
    });
    this._tourService.steps = [];
    this._tourService.initialize(steps, {
      enableBackdrop: false,
    });
  }

  private _queueSteps(steps: RosettaStepOption[]): void {
    this._queuedSteps.push(steps);
    this._startListenerForEnd();
  }

  private _getConfig(): RosettaTourState {
    const state = this._storage.getItem<RosettaTourState>(
      StorageKey.TourConfig
    );

    if (!state || state.tourVersion !== TOUR_CONFIG.version) {
      return { tourVersion: TOUR_CONFIG.version, seenStepIds: new Set() };
    }

    return {
      ...state,
      seenStepIds: new Set(state.seenStepIds),
    };
  }

  private _setConfig(state: RosettaTourState): void {
    this._storage.setItem(StorageKey.TourConfig, {
      ...state,
      seenStepIds: Array.from(state.seenStepIds),
    });
  }

  private _getId(step: RosettaStepOption): string {
    return step.stepId || step.anchorId || '';
  }

  private _setup(): void {
    this._tourService.stepHide$
      .pipe(takeUntil(this._tourService.end$))
      .subscribe(({ step }) => this._updateTourConfig([step]));
  }

  private _checkStepSeen(step: RosettaStepOption): boolean {
    return this._getConfig()?.seenStepIds.has(this._getId(step));
  }

  private _removeSeenSteps(steps: RosettaStepOption[]): RosettaStepOption[] {
    return steps.filter(step => !this._checkStepSeen(step));
  }

  private _updateTourConfig(steps: RosettaStepOption[]): void {
    if (steps.length > 0) {
      const config = this._getConfig();
      steps.forEach(step => config.seenStepIds.add(this._getId(step)));
      this._setConfig(config);
    }
  }

  private _setupPopoverClass(steps: RosettaStepOption[]): void {
    steps.forEach((step: RosettaStepOption) => {
      let popoverClass = 'theme-bg';
      if (!!step.popoverClass) {
        popoverClass = `${popoverClass} ${step.popoverClass}`;
      }
      step.popoverClass = popoverClass;
    });
  }
}
