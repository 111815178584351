import { WorkspaceGroup } from '@models';
import { createReducer, on } from '@ngrx/store';
import * as actions from './projects.action';

export const projectsFeatureKey = 'projects';

export interface State {
  data: WorkspaceGroup[];
  loading: boolean;
  errorMessage: string | null;
}

export const initialState: Readonly<State> = {
  data: [],
  loading: false,
  errorMessage: null,
};

export const projectsReducer = createReducer(
  initialState,
  on(actions.loadProjects, state => {
    return {
      ...state,
      errorMessage: null,
      loading: true,
    };
  }),
  on(actions.loadProjectsSuccess, (state, { projects }) => {
    return {
      ...state,
      data: projects,
      loading: false,
    };
  }),
  on(actions.loadProjectsFailure, (state, { error }) => {
    return {
      ...state,
      loading: false,
      errorMessage: error,
    };
  })
);
