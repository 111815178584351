import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Inject,
  OnDestroy,
  OnInit,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { FontsModule } from '@app/fonts/fonts.module';
import { dialogResultOperator } from '@core/services/base-defer-dialog.service';
import { Store } from '@ngrx/store';
import { RosettaTableOptions } from '@shared/components/rosetta-table/models/rosetta-table.model';
import * as transform from '@shared/components/transform/models/data-viewer';
import { TransformSelectorManagerService } from '@shared/components/transform/services/selectors/transform-selector-manager.service';
import { ShowSpinnerDirective } from '@shared/directives/show-spinner.directive';
import { RosettaTourStartDirective } from '@shared/modules/rosetta-tour/rosetta-tour-start.directive';
import { provideTourSteps } from '@shared/modules/rosetta-tour/rosetta-tour.provider';
import { RosettaTitleCasePipe } from '@shared/pipes';
import { AppActions } from '@store/.';
import { combineLatest, first, map, startWith } from 'rxjs';
import { RunStatusComponent } from '../../components/run-status/run-status.component';
import { TransformDataViewerSelectorsComponent } from '../../components/transform-data-viewer-selectors/transform-data-viewer-selectors.component';
import { TransformDataViewerComponent } from '../../components/transform-data-viewer/transform-data-viewer.component';
import { TransformDialogService } from '../../dialogs/transform-dialog.service';
import { PipelineRunInfo, SampleRowState, TransformStatus } from '../../models';
import { PipelineRunData } from '../../models/pipeline-run-date.model';
import {
  ITransformConfig,
  TRANSFORM_CONFIG,
} from '../../models/transform-config.model';
import { TransformService } from '../../services/transform.service';

@Component({
  selector: 'app-transform-list',
  standalone: true,
  imports: [
    AsyncPipe,
    FontsModule,
    MatButtonModule,
    RosettaTitleCasePipe,
    RosettaTourStartDirective,
    RunStatusComponent,
    ShowSpinnerDirective,
    TransformDataViewerComponent,
    TransformDataViewerSelectorsComponent,
  ],
  templateUrl: './transform-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'transform-list' },
  providers: [provideTourSteps(import('../../transform.tour'))],
})
export class TransformListComponent implements OnInit, OnDestroy {
  constructor(
    private _store: Store,
    private _destroyRef: DestroyRef,
    private _transformDialogService: TransformDialogService,
    @Inject(TRANSFORM_CONFIG) private _transformConfig: ITransformConfig,
    public transformService: TransformService,
    public transformSelectorManager: TransformSelectorManagerService
  ) {}

  hideDeleteAction = signal(true);
  addActionDisabled = signal(true);
  options: RosettaTableOptions<transform.DataViewerRow> = {
    actions: [
      {
        tourAnchorId: 'add-sample',
        label: 'Add Samples',
        icon: 'add',
        $disabled: this.addActionDisabled.asReadonly(),
        onClick: () => this.openAddSampleDialog(),
      },
    ],
  };

  private _isTestPackSelectionValid$ =
    this.transformSelectorManager.testPackGridSelection$.pipe(
      map(selection => !!selection),
      startWith(false)
    );

  dataViewerId = `${this._transformConfig.type.toLowerCase()}-list`;

  showDataViewer$ = this.transformService.isSupported$.pipe(first());

  ngOnInit(): void {
    this.showDataViewer$
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(isSupported => {
        if (isSupported) {
          this.transformSelectorManager.init();
        }
      });

    this.transformSelectorManager.testPackSelected$
      .pipe(map(testPack => testPack?.status !== TransformStatus.Added))
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(hideDeleteAction => {
        this.hideDeleteAction.set(hideDeleteAction);
      });

    combineLatest([
      this._isTestPackSelectionValid$,
      this.transformService.runResultState$.pipe(startWith(null)),
    ])
      .pipe(
        map(
          ([testPackSelectionValid, runResultState]) =>
            !!testPackSelectionValid && this._isRunComplete(runResultState)
        )
      )
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(canAddSample => this.addActionDisabled.set(!canAddSample));
  }

  ngOnDestroy(): void {
    this.transformSelectorManager.cleanup();
  }

  onRowClicked(row: transform.DataViewerRow): void {
    if (row.sampleRowState === SampleRowState.Errored) {
      return;
    }

    this.transformService.goToTransformDetails(
      row.testPackGridSelection,
      row.sampleId
    );
  }

  onRowEvent(event: transform.DataViewerEvent): void {
    this.transformService.processTransformEvent(event).subscribe({
      next: () => {
        this._store.dispatch(
          AppActions.showBasicSuccessMsg({
            message: `${event.type} success on ${event.rows.length} sample${event.rows.length > 1 ? 's' : ''}`,
          })
        );
      },
      error: e => {
        this._store.dispatch(
          AppActions.showBasicErrorMsg({ message: e.message })
        );
      },
    });
  }

  openAddSampleDialog(): void {
    const selection = this.transformSelectorManager.getCurrentSelection();
    this._transformDialogService
      .openAddSample(selection)
      .pipe(dialogResultOperator())
      .subscribe(() => {
        this.transformService.rerun();
      });
  }

  private _isRunComplete(
    pipelineRunInfo: PipelineRunInfo<PipelineRunData> | null
  ): boolean {
    if (pipelineRunInfo === null) {
      return true;
    }

    if (
      pipelineRunInfo.result === null ||
      pipelineRunInfo.result.data === null
    ) {
      return false;
    }

    const { currentPipeline, totalPipelines } = pipelineRunInfo.result.details;
    return currentPipeline === totalPipelines;
  }
}
