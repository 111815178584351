import { NgTemplateOutlet } from '@angular/common';
import {
  Attribute,
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontsModule } from '@app/fonts/fonts.module';
import { IRosettaConfig, ROSETTA_CONFIG } from '@configs';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Color, Warning, WorkspaceModelInfo } from '@models';

interface IconData {
  icon: IconProp;
  tooltip: string;
  color: Color;
}

type FullMessage = 'Unsupported' | 'Deprecated' | undefined;

@Component({
  standalone: true,
  imports: [NgTemplateOutlet, FontsModule, MatTooltipModule],
  selector: 'app-upgrade-icon',
  templateUrl: './upgrade-icon.component.html',
  styleUrls: ['./upgrade-icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'upgrade-icon v2-style-wrapper',
  },
})
export class UpgradeIconComponent {
  constructor(
    @Inject(ROSETTA_CONFIG) public config: IRosettaConfig,
    @Attribute('warningType')
    public warningType: 'icon' | 'full' | 'none'
  ) {}

  @Input() set modelInfo(info: WorkspaceModelInfo | undefined) {
    if (!info) {
      this.showWarning = false;
      this.fullMessage = undefined;
      this.iconData = undefined;
      return;
    }

    this._modelInfo = info;
    this.modelId = info.modelShortname || info.modelId;
    this.showWarning = this.warningType !== 'none';
    this.fullMessage = this._getFullMessage(info);
    this.iconData = this._setIcon(info);
  }

  get modelInfo(): WorkspaceModelInfo | undefined {
    return this._modelInfo;
  }

  private _modelInfo?: WorkspaceModelInfo;
  fullMessage: FullMessage | undefined;
  showWarning = true;
  modelId = 'unknown';
  iconData: IconData | undefined;

  private _getFullMessage(info: WorkspaceModelInfo): FullMessage {
    if (this.warningType !== 'icon') {
      if (info.deprecationMessage) {
        return 'Deprecated';
      }
      if (info.upgradeWarning === 'major') {
        return 'Unsupported';
      }
    }
    return undefined;
  }

  private _setIcon({
    upgradeWarning,
  }: WorkspaceModelInfo): IconData | undefined {
    const iconDataMap: Record<
      Warning,
      Pick<IconData, 'icon' | 'color' | 'tooltip'> | undefined
    > = {
      none: undefined,
      major: {
        icon: 'exclamation-circle',
        color: this.config.color.warn,
        tooltip: this.config.text.unsupportedVersion,
      },
      minor: {
        icon: 'arrow-circle-up',
        color: this.config.color.primary,
        tooltip: 'Upgrade available',
      },
      unsupported: {
        icon: 'circle-xmark',
        color: this.config.color.warn,
        tooltip: 'Unsupported',
      },
    };

    return iconDataMap[upgradeWarning];
  }
}
