import { AgGridModule } from '@ag-grid-community/angular';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
  ColDef,
  Column,
  GridApi,
  GridOptions,
  GridReadyEvent,
  GridState,
  ModuleRegistry,
  RowClickedEvent,
  SelectionColumnDef,
  StateUpdatedEvent,
} from '@ag-grid-community/core';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewEncapsulation,
  signal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { FontsModule } from '@app/fonts/fonts.module';
import { ComponentStateService } from '@core/services';
import { StorageKey } from '@models';
import { Store } from '@ngrx/store';
import { MoreOptionsMenuItem } from '@shared/components/more-options-menu/more-options-menu.model';
import { rosettaTableCellComponentList } from '@shared/components/rosetta-table/cell-components';
import { rosettaTableEditorComponentList } from '@shared/components/rosetta-table/editor-components';
import { rosettaTableFilterComponentList } from '@shared/components/rosetta-table/filter-components';
import {
  RosettaTableOptions,
  RosettaTableState,
} from '@shared/components/rosetta-table/models/rosetta-table.model';
import { rosettaTableTooltipComponentList } from '@shared/components/rosetta-table/tooltip-components';
import { AppSelectors } from '@store/selectors';
import { deepMerge } from '@utils/object-utils';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { ReplaySubject, Subscription, combineLatest, map, tap } from 'rxjs';
import { MoreOptionsMenuComponent } from '../more-options-menu/more-options-menu.component';
import { TextInputComponent } from '../text-input/text-input.component';
import { TableColumnMenuComponent } from './menu-components/table-column-menu/table-column-menu.component';
import { COLUMN_TYPE_IGNORE } from './rosetta-table.helpers';

ModuleRegistry.registerModules([ClientSideRowModelModule, CsvExportModule]);

@Component({
  standalone: true,
  imports: [
    AgGridModule,
    AsyncPipe,
    FontsModule,
    MatButtonModule,
    MoreOptionsMenuComponent,
    TableColumnMenuComponent,
    TextInputComponent,
    TourMatMenuModule,
  ],
  selector: 'app-rosetta-table',
  templateUrl: './rosetta-table.component.html',
  styleUrls: [
    './rosetta-table.component.scss',
    './rosetta-table.component.media.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'rosetta-table',
  },
})
export class RosettaTableComponent<TData = any, TContext = unknown>
  implements OnChanges, OnDestroy
{
  constructor(
    private _store: Store,
    private _cmpState: ComponentStateService<Partial<RosettaTableState>>
  ) {}

  gridApi!: GridApi;
  context!: TContext;
  quickFilterText?: string;
  filterDisabled = false;
  initialised = signal(false);
  cacheKey?: StorageKey;
  initialState?: GridState;

  readonly components = {
    ...rosettaTableCellComponentList,
    ...rosettaTableTooltipComponentList,
    ...rosettaTableFilterComponentList,
    ...rosettaTableEditorComponentList,
  };

  readonly defaultGridOptions: GridOptions = {
    suppressCellFocus: true,
    suppressColumnMoveAnimation: true,
    loading: false,
    suppressClickEdit: true,
    rowBuffer: 10,
    columnTypes: {
      [COLUMN_TYPE_IGNORE]: {
        hide: true,
        initialHide: true,
        lockVisible: true,
      },
    },
  };
  readonly defaultColDefinitions: ColDef = {
    sortable: true,
    resizable: true,
    autoHeight: false,
    suppressMovable: true,
    minWidth: 100,
    getQuickFilterText: params => (params.colDef.hide ? '' : params.value),
  };

  downloadActionDisabled = signal(true);
  readonly downloadMenuAction: MoreOptionsMenuItem = {
    label: 'Download',
    icon: 'download',
    $disabled: this.downloadActionDisabled.asReadonly(),
    onClick: () => this.downloadDataset(),
  };

  @Output()
  gridReady = new EventEmitter<GridReadyEvent>();

  @Output()
  rowClicked = new EventEmitter<TData>();

  @Input({ required: true }) tableId: string;
  @Input({ required: true }) rowData: TData[] | undefined;
  @Input({ required: true }) colDefs: ColDef<TData>[] | null = [];
  @Input() selectionColDef: SelectionColumnDef | null = null;
  @Input() options: RosettaTableOptions = {};
  @Input() exportAsCsvColumnKeys?: (string | Column)[];
  @Input() gridOptions: GridOptions = {};
  @Input() defaultColDef: ColDef<TData> = {};

  private _sub = new Subscription();
  private _canUpdateState = false;
  private _dataSubject = new ReplaySubject<TData[] | undefined>(1);

  gridOptionsMerged = this.defaultGridOptions;
  defaultColDefMerged = this.defaultColDefinitions;

  noData$ = this._dataSubject.pipe(
    map(data => !data || data.length === 0),
    tap(noData => this.downloadActionDisabled.set(noData))
  );
  classes$ = combineLatest([
    this.noData$.pipe(map(nodata => (nodata ? 'rosetta-table-empty' : ''))),
    this._store
      .select(AppSelectors.isDarkTheme)
      .pipe(map(isDark => `ag-theme-alpine${isDark ? '-dark' : ''}`)),
  ]);

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.initialised() && changes['tableId']) {
      this._setCacheKey(this.tableId);
      this._setInitialState();
      this.initialised.set(true);
    }

    if (changes['rowData']) {
      this._dataSubject.next(this.rowData);
    }

    if (changes['gridOptions']) {
      this.gridOptionsMerged = deepMerge(
        this.gridOptionsMerged,
        this.gridOptions
      );
    }

    if (changes['defaultColDef']) {
      this.defaultColDefMerged = deepMerge(
        this.defaultColDefMerged,
        this.defaultColDef
      );
    }

    if (changes['options'] && this.options.canDownload) {
      this.options.actions = (this.options.actions || []).concat(
        this.downloadMenuAction
      );
    }
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  onGridReady(params: GridReadyEvent<TData, TContext>): void {
    this.gridApi = params.api;
    this.context = params.context;
    this._startDataUpdateListener();
    this.gridReady.emit(params);
  }

  updateFilter(filter: string): void {
    this.quickFilterText = filter;

    if (this.cacheKey && this._canUpdateState) {
      this._cmpState.set(this.cacheKey, { quickFilterText: filter }, true);
    }
  }

  enableFilter(): void {
    this.filterDisabled = false;
  }

  disableFilter(): void {
    this.filterDisabled = true;
  }

  onRowClicked(event: RowClickedEvent): void {
    this.rowClicked.emit(event.data);
  }

  downloadDataset(): void {
    const fileName =
      typeof this.options.getFileName === 'string'
        ? this.options.getFileName
        : this.options.getFileName?.();
    this.gridApi.exportDataAsCsv({
      fileName,
      columnKeys: this.exportAsCsvColumnKeys,
    });
  }

  onStateUpdated(event: StateUpdatedEvent): void {
    if (this.cacheKey && this._canUpdateState) {
      const state = event.state;
      const quickFilterText =
        this._cmpState.get(this.cacheKey)?.quickFilterText || '';
      this._cmpState.set(this.cacheKey, { state, quickFilterText });
    }
  }

  private _startDataUpdateListener(): void {
    this._sub.add(
      this._dataSubject.subscribe(data => {
        this.gridApi.setGridOption('rowData', data || []);
      })
    );
  }

  private _setInitialState(): void {
    if (this.cacheKey) {
      const tableState = this._getTableStateCache();
      this._setQuickFilterText(tableState);
      this.initialState = tableState.state;
    }
    this._canUpdateState = true;
  }

  private _getTableStateCache(): Partial<RosettaTableState> {
    if (!this.cacheKey) {
      return {};
    }
    return this._cmpState.get(this.cacheKey) || {};
  }

  private _setCacheKey(tableId?: string): void {
    if (tableId) {
      this.cacheKey = {
        key: `tbl-${tableId}`,
        global: this.options.globalState,
      };
    }
  }

  private _setQuickFilterText({
    quickFilterText,
  }: Partial<RosettaTableState>): void {
    if (quickFilterText) {
      this.quickFilterText = quickFilterText;
    }
  }
}
