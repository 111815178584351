import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DomainModelActions } from '@store/domain-models/domain-models.actions';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { map, switchMap } from 'rxjs';
import * as WorkspaceActions from '../workspace/actions';
import * as RecentWorkspacesActions from './recent-workspaces.actions';

export const switchWorkspace = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) => {
    return actions$.pipe(
      ofType(WorkspaceActions.switchWorkspaceSuccess),
      switchMap(() =>
        store.select(WorkspaceSelectors.selectWorkspaceInfo).pipe(
          map(workspaceInfo =>
            RecentWorkspacesActions.addRecentWorkspace({
              add: workspaceInfo,
            })
          )
        )
      )
    );
  },
  { functional: true }
);

export const closeWorkspaceSuccess = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) => {
    return actions$.pipe(
      ofType(WorkspaceActions.closeWorkspaceSuccess),
      switchMap(() =>
        store.select(WorkspaceSelectors.selectWorkspaceInfo).pipe(
          map(workspaceInfo =>
            RecentWorkspacesActions.addRecentWorkspace({
              add: workspaceInfo,
            })
          )
        )
      )
    );
  },
  { functional: true }
);

export const deleteWorkspaceSuccess = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(WorkspaceActions.deleteWorkspaceSuccess),
      map(({ workspaceId }) =>
        RecentWorkspacesActions.removeRecentWorkspace({
          remove: workspaceId,
        })
      )
    );
  },
  { functional: true }
);

export const getDomainModelsSuccess = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(DomainModelActions.loadSuccess),
      map(({ domainModels }) =>
        RecentWorkspacesActions.checkRecentWorkspaces({
          modelIds: domainModels.map(model => model.id),
        })
      )
    );
  },
  { functional: true }
);
