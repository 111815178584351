import { AfterViewInit, Directive, Inject, SkipSelf } from '@angular/core';
import { TOUR_STEPS_CONFIG } from './rosetta-tour.provider';
import { RosettaStepOption, RosettaTourService } from './rosetta-tour.service';

@Directive({
  selector: '[appRosettaTourStart]',
  standalone: true,
})
export class RosettaTourStartDirective implements AfterViewInit {
  constructor(
    private _rosettaTourService: RosettaTourService,
    @Inject(TOUR_STEPS_CONFIG)
    @SkipSelf()
    private steps: Promise<RosettaStepOption[]>
  ) {}

  async ngAfterViewInit(): Promise<void> {
    const loadedSteps = await this.steps;
    this._startTour(loadedSteps);
  }

  private _startTour(steps: RosettaStepOption[]): void {
    if (steps?.length) {
      this._rosettaTourService.loadTourSteps(steps);
      this._rosettaTourService.start();
    }
  }
}
