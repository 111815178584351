import { inject } from '@angular/core';
import { DomainModelApiService } from '@core/services';
import { LoginApiService } from '@features/auth/login/services/login-api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AuthActions } from '@store/.';
import { createDocumentIdMap } from '@store/workspace/effects/workspace.effects.helper';
import { catchErrorAndReturn } from '@utils/operators';
import { map, mergeMap, switchMap } from 'rxjs';
import { DomainModelActions } from './domain-models.actions';
import { acceptDomainModelAndMarkAsAccepted } from './domain-models.effects.helpers';

export const loginSuccessMapper = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(AuthActions.loginSuccess),
      map(() => DomainModelActions.load())
    );
  },
  { functional: true }
);

export const loadDomainModels = createEffect(
  (
    actions$ = inject(Actions),
    domainModelApi = inject(DomainModelApiService)
  ) => {
    return actions$.pipe(
      ofType(DomainModelActions.load),
      switchMap(() =>
        domainModelApi.getAll().pipe(
          map(domainModels =>
            DomainModelActions.loadSuccess({
              domainModels,
            })
          ),
          catchErrorAndReturn(
            DomainModelActions.loadFailure({
              errorMessage: 'Failed to load domain Models',
            })
          )
        )
      )
    );
  },
  { functional: true }
);

export const acceptDomainModel = createEffect(
  (
    actions$ = inject(Actions),
    loginApi = inject(LoginApiService),
    store = inject(Store)
  ) => {
    return actions$.pipe(
      ofType(DomainModelActions.acceptModelDocuments),
      switchMap(
        ({ modelId, documents }) =>
          loginApi.acceptDocuments(createDocumentIdMap(documents)).pipe(
            acceptDomainModelAndMarkAsAccepted(store, modelId),
            mergeMap(domainModel => [
              DomainModelActions.update({
                domainModel,
              }),
              AuthActions.acceptModelDocumentsSuccess({
                modelId,
                documents,
              }),
            ])
          )
        // TODO: Add error handling
      )
    );
  },
  { functional: true }
);

export const cleanupSessionStateMapper = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(AuthActions.cleanupSessionState),
      map(() => DomainModelActions.resetAll())
    );
  },
  { functional: true }
);
