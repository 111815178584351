import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import * as DomainModelsEffects from '@store/domain-models/domain-models.effects';
import { appEffects } from '@store/effects';
import { metaReducers } from '@store/meta.reducers';
import * as RecentWorkspacesEffects from '@store/recent-workspaces/recent-workspaces.effects';
import * as appStore from '@store/reducers';
import * as RouterEffects from '@store/router/router.effects';
import * as ServerStatusEffects from '@store/server-status/server-status.effects';
import * as WorkspaceLoadEffects from '@store/workspace/effects/workspace-load.effects';
import { WorkspaceEffects } from '@store/workspace/effects/workspace.effects';
import { CustomSerializer } from './custom-route-serializer';

@NgModule({
  imports: [
    StoreModule.forRoot(appStore.reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true,
        strictActionWithinNgZone: true,
        strictActionTypeUniqueness: true,
      },
    }),
    EffectsModule.forRoot([
      ...appEffects,
      WorkspaceEffects,
      DomainModelsEffects,
      WorkspaceLoadEffects,
      RouterEffects,
      RecentWorkspacesEffects,
      ServerStatusEffects,
    ]),
    StoreRouterConnectingModule.forRoot({ serializer: CustomSerializer }),
  ],
})
export class NgrxStoreModule {}
