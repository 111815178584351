import { ItemProblem } from '@features/workspace/models/rosetta-core.model';
import {
  IMenuItems,
  LoadingState,
  SelectedWorkspaceItemId,
  WorkspaceId,
  WorkspaceInfo,
  WorkspaceItemState,
  WorkspaceType,
} from '@models';
import { createReducer, on } from '@ngrx/store';
import { addUnique } from '@utils/array-utils';
import { TYPE_ROSETTA } from '@workspace-design/textual/models/editor.const';
import { ConnectionState } from '@workspace-design/textual/models/rosetta-editor.model';
import { produce } from 'immer';
import * as actions from '../actions';
import { getModelInitialFileId } from '../effects/workspace.effects.helper';
import {
  createWorkspaceItemId,
  createWorkspaceItemState,
  getItemModelName,
} from './workspace.reducers.helper';

export const featureKey = 'workspace';

export interface State {
  firstLoad: boolean;
  // UI state
  loadingState: LoadingState; // Fetching workspace data from server
  connectionState: ConnectionState; // All diagnostics received

  // Project + Workspace list
  menuItems: IMenuItems;

  // Current Workspace state
  workspaceId: WorkspaceId | null;
  workspaceInfo: WorkspaceInfo | null;

  // Workspace Item state (Files)
  selectedWorkspaceItemId: SelectedWorkspaceItemId | undefined;
  workspaceItems: WorkspaceItemState[];
  dirtyWorkspaceItems: string[];
  erroredWorkspaceItems: Record<string, ItemProblem[]>;
  saveErrorWorkspaceItems: string[];

  // Other state
  isDiffEditorOpen: boolean;
  hasRecompiledInvalidPojo: boolean;
}

export const initialState: Readonly<State> = {
  firstLoad: true,
  loadingState: LoadingState.INITIAL,
  connectionState: ConnectionState.Disconnected,
  workspaceId: null,

  isDiffEditorOpen: false,
  hasRecompiledInvalidPojo: false,

  selectedWorkspaceItemId: undefined,
  workspaceItems: [],
  dirtyWorkspaceItems: [],
  erroredWorkspaceItems: {},
  saveErrorWorkspaceItems: [],

  workspaceInfo: null,
  menuItems: { items: [], state: LoadingState.INITIAL, errorMessage: null },
};

export const reducer = createReducer(
  initialState,
  on(actions.switchWorkspace, state =>
    produce(state, draft => {
      draft.loadingState = LoadingState.LOADING;
      draft.workspaceId = null; // Set next workspace ID

      draft.isDiffEditorOpen = false;
      draft.hasRecompiledInvalidPojo = false;
      draft.workspaceItems = [];

      if (!draft.firstLoad) {
        // These are stored in local storage
        draft.selectedWorkspaceItemId = undefined;
        draft.workspaceInfo = null;
      }

      draft.dirtyWorkspaceItems = [];
      draft.erroredWorkspaceItems = {};
      draft.saveErrorWorkspaceItems = [];
    })
  ),
  on(actions.switchWorkspaceSuccess, state =>
    produce(state, draft => {
      draft.hasRecompiledInvalidPojo = false;
    })
  ),
  on(actions.switchWorkspaceFailure, state =>
    produce(state, draft => {
      draft.loadingState = LoadingState.ERROR;
      draft.connectionState = ConnectionState.Disconnected;
      draft.workspaceId = null;
    })
  ),
  on(actions.saveWorkspaceSuccess, (state, { workspaceItems, domainModels }) =>
    produce(state, draft => {
      // TODO: Move this outside of th reducer
      draft.workspaceItems = Array.from(
        new Map(
          [
            ...draft.workspaceItems,
            ...workspaceItems.map(item =>
              createWorkspaceItemState(
                item,
                getItemModelName(item, domainModels)
              )
            ),
          ].map(item => [item.info.uri, item])
        ).values()
      );

      draft.dirtyWorkspaceItems = [];
      draft.saveErrorWorkspaceItems = [];

      draft.hasRecompiledInvalidPojo = false;

      const selectedInfo = workspaceItems.find(
        item => item.info.uri === draft.selectedWorkspaceItemId?.uri
      )?.info;
      if (
        selectedInfo &&
        selectedInfo.modelName !== draft.selectedWorkspaceItemId.modelName
      ) {
        draft.selectedWorkspaceItemId = createWorkspaceItemId(selectedInfo);
      }
    })
  ),
  on(actions.saveWorkspaceFailure, state => ({
    ...state,
    dirtyWorkspaceItems: [],
    saveErrorWorkspaceItems: [...state.dirtyWorkspaceItems],
  })),
  on(actions.addWorkspaceItem, (state, { workspaceItem }) =>
    produce(state, draft => {
      draft.workspaceItems.push(workspaceItem);
      draft.selectedWorkspaceItemId = createWorkspaceItemId(workspaceItem.info);
    })
  ),
  on(actions.updateWorkspaceItemContent, (state, { items }) =>
    produce(state, draft => {
      items.forEach(({ uri, contents }) => {
        draft.dirtyWorkspaceItems = addUnique(draft.dirtyWorkspaceItems, uri);
        const item = draft.workspaceItems.find(i => i.info.uri === uri);
        if (!item) {
          return;
        }
        if (item.contents === contents) {
          return;
        }
        item.contents = contents;
        item.lastModified = Date.now();
      });
    })
  ),
  on(actions.loadWorkspaceItems, (state, { workspace }) =>
    produce(state, draft => {
      const itemsFiltered = workspace.items.filter(
        ({ info }) => info.fileType === TYPE_ROSETTA
      );

      if (
        !itemsFiltered.some(
          item =>
            item.info.uri === draft.selectedWorkspaceItemId?.uri &&
            item.info.modelName === draft.selectedWorkspaceItemId?.modelName
        )
      ) {
        draft.selectedWorkspaceItemId = undefined;
      }

      if (!draft.selectedWorkspaceItemId) {
        draft.selectedWorkspaceItemId = getModelInitialFileId(
          itemsFiltered,
          workspace.info?.modelId
        );
      }

      draft.loadingState = LoadingState.LOADED;
      draft.workspaceInfo = workspace.info;
      draft.workspaceId = draft.workspaceInfo.id; // HACK: as we don't know the full workspace ID on initial workspace switch due to us using the workspace name as an identifier
      draft.workspaceItems = itemsFiltered;
    })
  ),
  on(actions.revertWorkspaceItemConfirmed, (state, { uri }) =>
    produce(state, draft => {
      draft.dirtyWorkspaceItems = addUnique(draft.dirtyWorkspaceItems, uri);
      const item = draft.workspaceItems.find(i => i.info.uri === uri);

      if (!item) {
        return;
      }

      item.contents = item.originalContents;
      item.lastModified = Date.now();
    })
  ),
  on(actions.updateDiagnostics, (state, { diagnostics }) =>
    produce(state, draft => {
      diagnostics.forEach(d => {
        if (d.problems.length > 0) {
          draft.erroredWorkspaceItems[d.uri] = d.problems;
        } else if (draft.erroredWorkspaceItems[d.uri]) {
          delete draft.erroredWorkspaceItems[d.uri];
        }
      });
    })
  ),
  on(actions.workspaceReady, state => ({
    ...state,
    connectionState: ConnectionState.Ready,
  })),
  on(actions.selectWorkspaceItem, (state, { uri, openDiffEditor = false }) =>
    produce(state, draft => {
      draft.firstLoad = false;
      draft.selectedWorkspaceItemId = createWorkspaceItemId(
        draft.workspaceItems.find(item => item.info.uri === uri)?.info
      );

      if (draft.isDiffEditorOpen && openDiffEditor) {
        draft.isDiffEditorOpen = false;
      } else {
        draft.isDiffEditorOpen = openDiffEditor;
      }
    })
  ),
  on(actions.closeWorkspaceSuccess, actions.clearServices, state => ({
    ...state,
    loadingState: LoadingState.INITIAL,
    workspaceId: null,
    workspaceInfo: null,
    workspaceItems: [],
    selectedWorkspaceItemId: undefined,

    dirtyWorkspaceItems: [],
    erroredWorkspaceItems: {},
    saveErrorWorkspaceItems: [],
  })),
  on(actions.navigateToWorkspaces, state => ({
    ...state,
    loadingState: LoadingState.INITIAL,
  })),
  on(actions.closeWorkspace, state => ({
    ...state,
    loadingState: LoadingState.CLOSED,
  })),
  on(actions.selectFirstWorkspaceItemWithChanges, state =>
    produce(state, draft => {
      const itemInfo = draft.workspaceItems.find(
        item => item.contents !== item.originalContents
      )?.info;
      draft.selectedWorkspaceItemId = itemInfo
        ? createWorkspaceItemId(itemInfo)
        : undefined;
    })
  ),
  on(actions.loadProjectsSuccess, (state, { modelItems }) =>
    produce(state, draft => {
      // Replace all projects with new projects received
      const filteredMenuItems = draft.menuItems.items.filter(
        item => item.workspaceType !== WorkspaceType.Project
      );
      draft.menuItems.items = [...filteredMenuItems, ...modelItems];
    })
  ),
  on(actions.loadWorkspaces, state =>
    produce(state, draft => {
      draft.menuItems.state = LoadingState.LOADING;
      draft.menuItems.errorMessage = null;
    })
  ),
  on(actions.loadWorkspacesSuccess, (state, { workspaceMenuItems }) =>
    produce(state, draft => {
      draft.menuItems.state = LoadingState.LOADED;
      draft.menuItems.items = workspaceMenuItems;
    })
  ),
  on(actions.loadWorkspacesFailure, state =>
    produce(state, draft => {
      draft.menuItems.state = LoadingState.INITIAL;
      draft.menuItems.errorMessage = 'Something went wrong!';
    })
  ),
  on(actions.deleteWorkspace, (state, { workspaceId }) =>
    produce(state, draft => {
      draft.menuItems.items.forEach(item => {
        if (item.id.name === workspaceId.name) {
          item.isDeleted = true;
        }
      });
    })
  ),
  on(actions.deleteWorkspaceSuccess, (state, { workspaceId }) =>
    produce(state, draft => {
      draft.menuItems.items = draft.menuItems.items.filter(
        item => item.id.name !== workspaceId.name
      );
    })
  ),
  on(actions.deleteWorkspaceFailure, (state, { workspaceId }) =>
    produce(state, draft => {
      draft.menuItems.items.forEach(item => {
        if (item.id.name === workspaceId.name) {
          item.isDeleted = false;
        }
      });
    })
  ),
  on(
    actions.updateWorkspaceLoadingState,
    (state, { state: workspaceLoadingState }) => ({
      ...state,
      connectionState: workspaceLoadingState,
    })
  ),
  on(actions.cleanupSessionState, _ => ({
    ...initialState,
  })),
  on(actions.recompileInvalidPojo, state =>
    produce(state, draft => {
      draft.hasRecompiledInvalidPojo = true;
    })
  )
);
